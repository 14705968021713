import styled from '@emotion/styled';
import Flex from 'components/ui/Flex';
import { useRef, useState } from 'react';
import { css } from '@emotion/react';
import { useMediaQuery } from 'react-responsive';

import Typography from 'components/ui/Typography';
import { ReactComponent as ArrowLeft } from 'assets/icons/circle-arrow-left.svg';
import { ReactComponent as ArrowRight } from 'assets/icons/circle-arrow-right.svg';
import FlexItem from 'components/ui/Flex/FlexItem';
import useIntersectionObserver from 'hooks/useIntersectionObserver';
import { breakPoint, palette } from 'shared/styles';

const benefits = [
  {
    title: '추가 연차 제공',
    description:
      'CDRI Only 추가연차+법정기준연차까지 최대 19개 연차, 기념일 히든 연차(결혼, 생일 등)',
    imageFilename: 'job-c-benefit-1.png',
  },
  {
    title: '식대 제공',
    description: '밥값 걱정 없도록 점심식대 월 20만원 & 저녁식대 9,500원 제공',
    imageFilename: 'job-c-benefit-2.png',
  },
  {
    title: '효율적인 업무를 위한 고사양 장비 지원',
    description: '개발직군 맥북프로, 듀얼 모니터 등 원하는 장비 지원',
    imageFilename: 'job-c-benefit-3.png',
  },
  {
    title: '유연출퇴근제',
    description: '8시~10시 유연 출퇴근 제도 운영',
    imageFilename: 'job-c-benefit-5.png',
  },
  {
    title: '개인 성장지원금 지원',
    description: '온오프 교육 & 헬스케어 등 개인 성장지원금 60만원 지원',
    imageFilename: 'job-c-benefit-6.png',
  },
  {
    title: '도서 지원',
    description: '도서지원금 월 50,000원 & 자유로운 책 리뷰 공유',
    imageFilename: 'job-c-benefit-7.png',
  },
  {
    title: '회사 이익 공유',
    description: '매년 우수사원 스톡 옵션 제공',
    imageFilename: 'job-c-benefit-8.png',
  },
];

const SectionC = () => {
  const benefitListRef = useRef<HTMLUListElement | null>(null);
  const handleClickScroll = (direction: 'LEFT' | 'RIGHT') => {
    if (benefitListRef.current) {
      benefitListRef.current.scrollTo({
        top: 0,
        left:
          benefitListRef.current.scrollLeft +
          200 * (direction === 'LEFT' ? -1 : 1),
        behavior: 'smooth',
      });
    }
  };
  const [isPrevActive, setIsPrevActive] = useState(false);
  const [isNextActive, setIsNextActive] = useState(true);
  const handleBenefitIntersecting = ({
    isIntersecting,
    type,
  }: {
    isIntersecting: boolean;
    type: 'FIRST' | 'LAST';
  }) => {
    if (type === 'FIRST') {
      setIsPrevActive(!isIntersecting);
    } else {
      setIsNextActive(!isIntersecting);
    }
  };
  const isMobile = useMediaQuery({
    query: breakPoint.MOBILE,
  });
  const isSmallDevice = useMediaQuery({
    query: breakPoint.SMALL_DEVICE,
  });
  const isLD = useMediaQuery({
    query: breakPoint.LARGE_DEVICE,
  });
  const isTabletToLD = useMediaQuery({
    query: breakPoint.TABLET_TO_LARGE_DEVICE,
  });

  return (
    <Container>
      <Content isLD={isLD}>
        <Typography.Title
          type="h1"
          css={css`
            ${isMobile &&
            css`
              font-size: 24px;
              line-height: 34px;
            `}
            ${isTabletToLD &&
            css`
              font-size: 36px;
              line-height: 46px;
            `}
          `}
        >
          Benefit
          {!isMobile ? <br /> : ' '}
          이런 점이 좋아요!
        </Typography.Title>
        <Typography.Title
          type="b1"
          color="DARK_GREY"
          css={css`
            margin-top: 24px;

            ${isMobile &&
            css`
              font-size: 14px;
              line-height: 22px;
              word-break: keep-all;
            `}
            ${isTabletToLD &&
            css`
              font-size: 18px;
              line-height: 28px;
              word-break: keep-all;
            `}
          `}
        >
          CDRI는 한 사람 한 사람이 잘 성장해 나갈 수 있도록 지원합니다.
          <br /> 또한, 빠르게 변화하는 시대에 스타트업의 성장에 최고의 효율성과
          성과를
          {!isMobile && <br />} 가져올 수 있도록 구성원을 지원합니다.
        </Typography.Title>
        <Flex align="center" gap={20}>
          {!isSmallDevice && (
            <FlexItem>
              <StyledArrowLeft
                active={isPrevActive}
                onClick={() => isPrevActive && handleClickScroll('LEFT')}
              />
            </FlexItem>
          )}
          <BenefitList ref={benefitListRef} isTabletToLD={isTabletToLD}>
            {benefits.map((benefit, index) => (
              <Benefit
                key={benefit.title}
                index={index}
                {...benefit}
                onIntersect={handleBenefitIntersecting}
              />
            ))}
          </BenefitList>
          {!isSmallDevice && (
            <FlexItem>
              <StyledArrowRight
                active={isNextActive}
                onClick={() => isNextActive && handleClickScroll('RIGHT')}
              />
            </FlexItem>
          )}
        </Flex>
      </Content>
    </Container>
  );
};

export default SectionC;

const Container = styled.section``;

const Content = styled.div<{ isLD: boolean }>`
  padding: 120px 0px 200px;
  max-width: 1200px;
  margin: 0 auto;

  ${({ isLD }) =>
    isLD &&
    css`
      width: 100%;
      padding: 80px 24px;
    `}
`;

const BenefitList = styled.ul<{ isTabletToLD: boolean }>`
  display: flex;
  column-gap: 24px;
  margin-top: 80px;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;

  &::-webkit-scrollbar {
    display: none;
  }

  ${({ isTabletToLD }) =>
    isTabletToLD &&
    css`
      margin-top: 48px;
    `}
`;

const StyledArrowLeft = styled(ArrowLeft)<{ active: boolean }>`
  cursor: pointer;

  ${({ active }) =>
    !active &&
    css`
      cursor: not-allowed !important;

      path {
        fill: ${palette.GREY2};
      }

      circle {
        stroke: ${palette.GREY2};
      }
    `}
`;

const StyledArrowRight = styled(ArrowRight)<{ active: boolean }>`
  cursor: pointer;

  ${({ active }) =>
    !active &&
    css`
      cursor: not-allowed !important;

      path {
        fill: ${palette.GREY2};
      }

      circle {
        stroke: ${palette.GREY2};
      }
    `}
`;

const Benefit = ({
  index,
  title,
  description,
  imageFilename,
  onIntersect,
}: {
  index: number;
  title: string;
  description: string;
  imageFilename: string;
  onIntersect: ({
    isIntersecting,
    type,
  }: {
    isIntersecting: boolean;
    type: 'FIRST' | 'LAST';
  }) => void;
}) => {
  const target = useIntersectionObserver<HTMLLIElement>({
    onIntersect: (isIntersecting) => {
      onIntersect({ isIntersecting, type: index === 0 ? 'FIRST' : 'LAST' });
    },
  });
  const isMobile = useMediaQuery({
    query: breakPoint.MOBILE,
  });
  const isSmallDevice = useMediaQuery({
    query: breakPoint.SMALL_DEVICE,
  });

  return (
    <BenefitContainer
      isMobile={isMobile}
      ref={index === 0 || index === benefits.length - 1 ? target : undefined}
    >
      <BenefitImage
        src={`https://cdri.s3.ap-northeast-2.amazonaws.com/assets/${imageFilename}`}
        alt="benefit"
      />
      <Typography.Title
        type="s1"
        css={css`
          margin-top: 24px;

          ${isSmallDevice &&
          css`
            font-size: 18px;
            line-height: 28px;
          `}
        `}
      >
        {title}
      </Typography.Title>
      <Typography.Title
        type="b3"
        color="DARK_GREY"
        css={css`
          margin-top: 8px;
          word-break: keep-all;

          ${isSmallDevice &&
          css`
            font-size: 14px;
            line-height: 22px;
          `}
        `}
      >
        {description}
      </Typography.Title>
    </BenefitContainer>
  );
};

const BenefitContainer = styled.li<{ isMobile: boolean }>`
  flex: 0 0 370px;

  ${({ isMobile }) =>
    isMobile &&
    css`
      flex: 0 0 272px;
    `}

  scroll-snap-align: start;
`;

const BenefitImage = styled.img`
  display: block;
  width: 100%;
`;
