import { ReactComponent as NormalLogo } from 'assets/icons/logo.svg';
import { ReactComponent as WhiteLogo } from 'assets/icons/white-logo.svg';
import { ReactComponent as GreyLogo } from 'assets/icons/grey-logo.svg';

const Logo = ({ color = 'WHITE' }: { color?: 'WHITE' | 'NORMAL' | 'GREY' }) => {
  if (color === 'WHITE') {
    return <WhiteLogo />;
  }

  if (color === 'GREY') {
    return <GreyLogo />;
  }

  return <NormalLogo />;
};

export default Logo;
