import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useMediaQuery } from 'react-responsive';

import Flex from 'components/ui/Flex';
import Typography from 'components/ui/Typography';
import useScroll from 'hooks/useScroll';
import { breakPoint } from 'shared/styles';

const SectionA = () => {
  const target = useScroll();
  const isLD = useMediaQuery({
    query: breakPoint.LARGE_DEVICE,
  });
  const isMobile = useMediaQuery({
    query: breakPoint.MOBILE,
  });
  const isTabletToLD = useMediaQuery({
    query: breakPoint.TABLET_TO_LARGE_DEVICE,
  });

  return (
    <Container ref={target}>
      <Flex
        align="center"
        css={css`
          height: 100%;
          max-width: 1200px;
          margin: 0 auto;

          ${isLD &&
          css`
            padding: 0px 24px;
          `}
        `}
      >
        <Typography.Title
          type="h1"
          color="WHITE"
          css={css`
            ${isMobile &&
            css`
              font-size: 24px;
              line-height: 34px;
            `};
            ${isTabletToLD &&
            css`
              font-size: 36px;
              line-height: 46px;
            `}
          `}
        >
          뷰티산업의 문제를 함께 해결하고 싶습니다.
          <br />
          지금 CDRI 팀에 합류하세요
        </Typography.Title>
      </Flex>
    </Container>
  );
};

export default SectionA;

const Container = styled.section`
  height: 100vh;
  position: relative;
  background-color: #000;
  overflow: hidden;
  background-image: url('https://cdri.s3.ap-northeast-2.amazonaws.com/assets/job-a.png');
  background-size: cover;
  background-position: center;
`;
