import BusinessDetail from './BusinessDetail';
import { ReactComponent as Logo } from 'assets/icons/cosbridge-logo.svg';

const business = {
  title: '화장품 산업을 이어주는\n온 · 오프라인형 커뮤니티',
  description: `화장품 산업에는 왜 단순한 정보를 교류하는 커뮤니티만 있을까?
  화장품 산업을 유기적으로 연결할 수 있는 방법은 없을까? 생각했습니다.
  그래서 우리는 화장품 산업을 한 곳에 모아 다방향적 소통을 이루어 낼 수 있는 세션을 기반으로 업종에 구애 받지 않는 소통으로 편리하고 활발하게 의견을 공유할 수 있는 커뮤니티를 만들었습니다.
  화장품 산업에 전반적으로 흩어져 있는 지식을 모으고 소통할 수 있는 장소를 마련할 것 입니다.
  
  Cosbridge는 세션을 통해 오프라인의 연결을 선도하고 커뮤니티를 통해 온라인의 연결을 주도하며, 화장품 산업 전반을 아울러 이어주는 커뮤니티 플랫폼입니다.`,
  link: 'https://www.cosbridge.pro',
  imageUrl:
    'https://cdri.s3.ap-northeast-2.amazonaws.com/assets/business-c.png',
};

const SectionC = () => {
  return <BusinessDetail {...business} reverse logo={<Logo />} />;
};

export default SectionC;
