import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { ReactComponent as Download } from 'assets/icons/download.svg';
import { ReactComponent as CI } from 'assets/images/about-us-g_1.svg';
import { ReactComponent as BI } from 'assets/images/about-us-g_2.svg';
import { ReactComponent as CIColor } from 'assets/images/about-us-g_3.svg';
import { ReactComponent as BIColor } from 'assets/images/about-us-g_4.svg';
import Flex from 'components/ui/Flex';
import FlexItem from 'components/ui/Flex/FlexItem';
import Typography from 'components/ui/Typography';
import { breakPoint, palette } from 'shared/styles';

const SectionD = () => {
  const downloadLogo = (type: 'CI' | 'BI') => {
    const link = document.createElement('a');
    link.href = `https://cdri.s3.ap-northeast-2.amazonaws.com/assets/CDRI-${type}+Logo.zip`;
    link.click();
  };
  return (
    <Container>
      <Typography.Title
        align="center"
        css={css`
          @media ${breakPoint.MOBILE} {
            font-size: 24px;
            line-height: 34px;
          }
          @media ${breakPoint.TABLET_TO_LARGE_DEVICE} {
            font-size: 36px;
            line-height: 46px;
          }
        `}
      >
        CI & BI
      </Typography.Title>
      <StyledFlex gap={24}>
        <FlexItem flex="1 1 50%">
          <Typography.Title type="b2">CI SIGNATURE</Typography.Title>
          <CIContainer justify="center">
            <CI />
          </CIContainer>
          <CIColor
            css={css`
              display: block;
              margin: 36px auto 0;

              @media ${breakPoint.MOBILE} {
                margin-top: 24px;
                height: 56px;
              }
              @media ${breakPoint.TABLET_TO_LARGE_DEVICE} {
                margin-top: 36px;
                height: 72px;
              }
            `}
          />
          <DownloadButton onClick={() => downloadLogo('CI')}>
            CI 다운로드 <Download />
          </DownloadButton>
        </FlexItem>
        <FlexItem flex="1 1 50%">
          <Typography.Title
            type="b2"
            css={css`
              @media ${breakPoint.MOBILE} {
                margin-top: 80px;
              }
              @media ${breakPoint.TABLET_TO_LARGE_DEVICE} {
                margin-top: 56px;
              }
            `}
          >
            BI SIGNATURE
          </Typography.Title>
          <BIContainer justify="center">
            <BI />
          </BIContainer>
          <BIColor
            css={css`
              display: block;
              margin: 36px auto 0;

              @media ${breakPoint.MOBILE} {
                margin-top: 24px;
                width: 312px;
                height: 56px;
              }
              @media ${breakPoint.TABLET_TO_LARGE_DEVICE} {
                width: 424px;
                height: 72px;
              }
            `}
          />
          <DownloadButton onClick={() => downloadLogo('BI')}>
            BI 다운로드 <Download />
          </DownloadButton>
        </FlexItem>
      </StyledFlex>
    </Container>
  );
};

export default SectionD;

const Container = styled.section`
  height: auto !important;
  max-width: 1200px;
  margin: 0 auto;
  padding: 200px 0 !important;

  @media ${breakPoint.MOBILE} {
    padding: 80px 24px !important;
  }
  @media ${breakPoint.TABLET_TO_LARGE_DEVICE} {
    padding: 120px 24px 80px !important;
  }
`;

const StyledFlex = styled(Flex)`
  margin-top: 180px;
  flex-wrap: wrap;

  @media ${breakPoint.MOBILE} {
    margin-top: 56px;
  }
  @media ${breakPoint.TABLET_TO_LARGE_DEVICE} {
    margin-top: 106px;
  }
`;

const CIContainer = styled(Flex)`
  margin-top: 24px;
  padding: 146px 0;
  background-color: ${palette.LIGHT_GREY1};

  @media ${breakPoint.MOBILE} {
    margin-top: 16px;
    padding: 64px 42px;
  }
  @media ${breakPoint.TABLET_TO_LARGE_DEVICE} {
    margin-top: 24px;
    padding: 146px 96px;
  }

  & > svg {
    margin: 0 auto;
    @media ${breakPoint.MOBILE} {
      width: 228px;
      height: 74px;
    }
    @media ${breakPoint.TABLET_TO_LARGE_DEVICE} {
      width: 526px;
      height: 170px;
    }
  }
`;

const BIContainer = styled(Flex)`
  margin-top: 24px;
  padding: 76px 0px;
  background-color: ${palette.LIGHT_GREY1};

  @media ${breakPoint.MOBILE} {
    margin-top: 16px;
    padding: 32px 62px;
  }
  @media ${breakPoint.TABLET_TO_LARGE_DEVICE} {
    margin-top: 24px;
    padding: 76px 142px;
  }

  & > svg {
    margin: 0 auto;
    @media ${breakPoint.MOBILE} {
      width: 188px;
      height: 134px;
    }
    @media ${breakPoint.TABLET_TO_LARGE_DEVICE} {
      width: 434px;
      height: 308px;
    }
  }
`;

const DownloadButton = styled.button`
  display: flex;
  align-items: center;
  margin: 56px auto 0;
  border: 1px solid ${palette.PRIMARY};
  padding: 16px 46px;
  background-color: ${palette.WHITE};
  font-size: 16px;
  column-gap: 8px;
  color: ${palette.PRIMARY};
  cursor: pointer;
  border-radius: 172px;

  @media ${breakPoint.MOBILE} {
    margin-top: 36px;
  }
`;
