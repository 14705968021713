import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useMediaQuery } from 'react-responsive';

import Flex from 'components/ui/Flex';
import Typography from 'components/ui/Typography';
import { breakPoint, palette } from 'shared/styles';

const advisers = [
  {
    initial: 'jsj',
    name: '장성제 고문',
    comment:
      '아무리 가까운 길이라도 가지 않으면 도달하지 못하고, 아무리 쉬운 일이라도 하지 않으면 이루지 못한다.',
    careers: [
      '보건복지부 국립보건원 의료기기 표준과장',
      '국립보건안전연구원 독성부 생식독성과장',
      '식약청 의약품평가부장',
      '의약품정책연구소 자문위원',
      '대한약사회 부회장',
      '한국응용약물학회 부회장',
      '중앙약사심의위원회 위원',
      '충북대학교 약학대학 겸임교수',
      '상명대학교 공업화학과 겸임교수',
      '(주) 엠디코스랩 공동대표',
    ],
  },
  {
    initial: 'css',
    name: '최상숙 고문',
    comment:
      '위대한 일을 성취하려면 행동 뿐만 아니라 꿈을 꾸어야하며, 계획을 세우는 것 뿐만 아니라 그것을 믿어야 한다.',
    careers: [
      '보건복지부 식품의약품안전본부 의료용품과장',
      '식약청 의약품평가부 의약외품평가과장',
      '화장품의약외품과장',
      '바이오생약심사부 화장품심사과장',
      '경인식약청 시험분석센터장',
      '대한화장품학회 이사',
      '대한화장품협회 교육원장',
      '대한화장품학회 부회장',
      '식약처 해외정보리포터 평가위원',
      '식약처 중앙약사심의위원회 전문가',
      '충북대학교 약학대학원 화장품산업학과 겸임교수',
      '(주) 엠디코스랩 공동대표',
    ],
  },
  {
    initial: 'jkh',
    name: '정규환 고문',
    comment: '미래는 기다리는 자의 것이 아니라\n만드는 자의 것',
    careers: [
      'SK텔레콤 플랫폼기술원 매니저',
      'SK플래닛 플랫폼기술원 매니저',
      '삼성전자 종합기술원 전문연구원',
      '(주)뷰노 공동창업자 및 CTO',
      '성균관대학교 삼성융합의과학원 조교수',
      '삼성서울병원 미래의학연구원 조교수',
      '대한의료인공지능학회 기획이사',
      '대한의학영상정보학회 학술이사',
      '씨디알아이 고문',
    ],
  },
  {
    initial: 'pjh',
    name: '배재훈 고문',
    comment: '하루하루를 기쁘게 살자',
    careers: [
      '고려대학교 전자공학과 졸업',
      '숭실대학교 경영학 박사',
      'LG전자 비즈니스솔루션사업본부 마케팅담당 부사장',
      '범한판토스 대표이사',
      '대한상공회의소 물류위원회 위원장',
      '현대상선 대표이사',
      'HMM대표이사',
      '우송정보대학 산학협력 부총장',
      '씨디알아이 고문',
    ],
  },
];

const SectionB = () => {
  const isMD = useMediaQuery({ query: breakPoint.MEDIUM_DEVICE });
  const isMobile = useMediaQuery({ query: breakPoint.MOBILE });
  const isTablet = useMediaQuery({ query: breakPoint.TABLET });

  return (
    <Container>
      <Content isMobile={isMobile} isTablet={isTablet}>
        <Typography.Title
          type={'h1'}
          css={css`
            ${isTablet &&
            css`
              font-size: 36px;
              line-height: 46px;
            `}
            ${isMobile &&
            css`
              font-size: 24px;
              line-height: 34px;
            `}
          `}
        >
          ADVISER
        </Typography.Title>
        <Flex
          $wrap={true}
          gap={24}
          rowGap={24}
          direction={isMD ? 'column' : 'row'}
          css={css`
            margin-top: 56px;

            ${isMobile &&
            css`
              margin-top: 36px;
            `}
          `}
        >
          {advisers.map((adviser) => (
            <Adviser key={adviser.name} {...adviser} />
          ))}
        </Flex>
      </Content>
    </Container>
  );
};

export default SectionB;

const Container = styled.section`
  background-color: ${palette.LIGHT_GREY1};
  padding-top: 0 !important;
`;

const Content = styled.div<{ isMobile: boolean; isTablet: boolean }>`
  max-width: 1248px;
  margin: 0 auto;
  padding: 120px 24px 200px;

  ${({ isTablet }) =>
    isTablet &&
    css`
      padding-top: 80px;
    `}

  ${({ isMobile }) =>
    isMobile &&
    css`
      padding-top: 56px;
    `}
`;

const Adviser = ({
  initial,
  name,
  comment,
  careers,
}: {
  initial: string;
  name: string;
  comment: string;
  careers: string[];
}) => {
  const isMD = useMediaQuery({ query: breakPoint.MEDIUM_DEVICE });
  const isMobile = useMediaQuery({ query: breakPoint.MOBILE });

  return (
    <AdviserContainer isMobile={isMobile} isMD={isMD}>
      <Flex gap={36} direction={isMobile ? 'column' : 'row'}>
        <ProfileImage
          src={`https://cdri.s3.ap-northeast-2.amazonaws.com/assets/profile/adviser_${initial}.png`}
          alt="profile"
          isMobile={isMobile}
          style={{ flex: '0 0 160px' }}
        />
        <AdviserInfo isMobile={isMobile}>
          <Typography.Title
            type="h3"
            align={isMobile ? 'center' : 'left'}
            css={css`
              ${isMobile &&
              css`
                font-size: 18px;
                line-height: 28px;
              `}
            `}
          >
            {name}
          </Typography.Title>
          <Typography.Title
            type="s2"
            align={isMobile ? 'center' : 'left'}
            color="DARK_GREY"
            weight={700}
            css={css`
              margin-top: 8px;
              word-break: keep-all;
              white-space: pre-wrap;

              ${isMobile &&
              css`
                margin-top: 24px;
              `}
            `}
          >
            {comment}
          </Typography.Title>
          <CareerContainer isMobile={isMobile}>
            {careers.map((career) => (
              <Typography.Title type="b3" color="DARK_GREY" key={career}>
                <li>{career}</li>
              </Typography.Title>
            ))}
          </CareerContainer>
        </AdviserInfo>
      </Flex>
    </AdviserContainer>
  );
};

const AdviserContainer = styled.div<{
  isMobile: boolean;
  isMD: boolean;
}>`
  flex: 1 1 calc(50% - 12px);
  padding: 32px;
  background-color: #fff;
  border-radius: 12px;

  ${({ isMD }) =>
    isMD &&
    css`
      :nth-of-type(n + 2) {
        margin-top: 24px;
      }
    `}

  ${({ isMobile }) =>
    isMobile &&
    css`
      padding: 32px 16px;
    `}
`;

const AdviserInfo = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1;

  ${({ isMobile }) =>
    isMobile &&
    css`
      max-width: 300px;
      margin: 32px auto 0;
    `}
`;

const ProfileImage = styled.img<{ isMobile: boolean }>`
  display: block;
  width: 160px;
  height: 160px;
  margin: 0 auto;
`;

const CareerContainer = styled.ul<{ isMobile: boolean }>`
  margin-top: 24px;
  padding-left: 24px;

  li {
    list-style: disc;
  }

  ${({ isMobile }) =>
    isMobile &&
    css`
      margin: 24px auto 0;
    `}
`;
