import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useMediaQuery } from 'react-responsive';

import { ReactComponent as LogoFooter } from 'assets/icons/logo-footer.svg';
import Flex from 'components/ui/Flex';
import Typography from 'components/ui/Typography';
import { breakPoint, palette } from 'shared/styles';

const Footer = () => {
  const isSD = useMediaQuery({
    query: breakPoint.SMALL_DEVICE,
  });

  return (
    <Container>
      <Content>
        <StyledFlex>
          <LogoFooter color="GREY" />
          <InfoContainer>
            <Typography.Title
              type="small"
              color="GREY"
              css={css`
                white-space: pre-wrap;
              `}
            >
              {`주식회사 씨디알아이 | 대표 김준일 | 사업자 등록번호 673-86-01908 | 통신판매업신고번호 제2020-충북청주-2866호 | 대표 번호 02-6225-5253${
                !isSD ? '\n' : ' '
              }본사: 충북 청주시 흥덕구 오송읍 오송생명 1로 194-25, SB플라자 3층 A-13호 (28160) | 지사: 서울 강남구 논현로 417 화원빌딩 가든층 A (06246)`}
            </Typography.Title>
            <Typography.Title type="small" color="GREY">
              Copyright © CDRI CO., LTD. All Rights Reserved.
            </Typography.Title>
          </InfoContainer>
        </StyledFlex>
      </Content>
    </Container>
  );
};

export default Footer;

const Container = styled.section`
  padding: 53px 0 !important;
  height: auto !important;
  background-color: ${palette.SLATE_GREY10};

  @media ${breakPoint.SMALL_DEVICE} {
    padding: 0px !important;
  }
`;

const StyledFlex = styled(Flex)`
  max-width: 1200px;
  margin: 0 auto;
  align-items: center;
  justify-content: space-between;

  @media ${breakPoint.SMALL_DEVICE} {
    flex-direction: column;
    align-items: flex-start;

    svg {
      width: 108px;
      height: 32px;
      margin-bottom: 36px;
    }
  }
`;

const Content = styled.div`
  margin: 0 auto;
  padding: 0 76px;
  width: 100%;

  @media ${breakPoint.SMALL_DEVICE} {
    flex-direction: column;
    padding: 36px 24px;
  }
`;

const InfoContainer = styled.div`
  margin-left: 200px;
  @media ${breakPoint.SMALL_DEVICE} {
    margin-left: 0px;
    font-size: 24px;
    line-height: 34px;
    word-break: keep-all;
  }
`;
