import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useRecoilValue } from 'recoil';
import { useLocation, Link } from 'react-router-dom';
import { useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import Logo from 'components/icons/Logo';
import Flex from 'components/ui/Flex';
import Typography from 'components/ui/Typography';
import { isMainScrollingState } from 'store/ui';
import { breakPoint, palette } from 'shared/styles';
import { ReactComponent as HamburgerMenu } from 'assets/icons/hamburger-menu.svg';
import ResponsiveMenuPopup from 'components/ui/Popup/ResponsiveMenuPopup';

const Header = () => {
  const isMainScrolling = useRecoilValue(isMainScrollingState);
  const isMobile = useMediaQuery({ query: breakPoint.MOBILE });
  const { pathname } = useLocation();
  const [responsiveMenuVisible, setResponsiveMenuVisible] = useState(false);

  return (
    <Container isScrolling={isMainScrolling}>
      <Navigator justify="space-between" align="center">
        <Link to="/">
          <LogoDiv $isMobile={isMobile}>
            <Logo color={isMainScrolling ? 'NORMAL' : 'WHITE'} />
          </LogoDiv>
        </Link>
        <MenuContainer>
          <Link to="/about-us">
            <LinkText
              active={pathname === '/about-us'}
              isScrolling={isMainScrolling}
              type="s2"
              color="WHITE"
            >
              ABOUT US
            </LinkText>
          </Link>
          <Link to="business">
            <LinkText
              active={pathname === '/business'}
              isScrolling={isMainScrolling}
              type="s2"
              color="WHITE"
            >
              BUSINESS
            </LinkText>
          </Link>
          <Link to="team">
            <LinkText
              active={pathname === '/team'}
              isScrolling={isMainScrolling}
              type="s2"
              color="WHITE"
            >
              TEAM
            </LinkText>
          </Link>
          <Link to="tech">
            <LinkText
              active={pathname === '/tech'}
              isScrolling={isMainScrolling}
              type="s2"
              color="WHITE"
            >
              TECH
            </LinkText>
          </Link>
          <Link to="job">
            <LinkText
              active={pathname === '/job'}
              isScrolling={isMainScrolling}
              type="s2"
              color="WHITE"
            >
              JOB
            </LinkText>
          </Link>
          <Link to="cs">
            <LinkText
              active={pathname === '/cs'}
              isScrolling={isMainScrolling}
              type="s2"
              color="WHITE"
            >
              CS CENTER
            </LinkText>
          </Link>
        </MenuContainer>
      </Navigator>
      <ResponsiveNavigator justify="space-between">
        <Link to="/">
          <LogoDiv $isMobile={isMobile}>
            <Logo color={isMainScrolling ? 'NORMAL' : 'WHITE'} />
          </LogoDiv>
        </Link>
        <HamburgerMenu
          fill={isMainScrolling ? palette.BLACK : '#ffffff'}
          onClick={() => {
            setResponsiveMenuVisible(true);
          }}
        />
      </ResponsiveNavigator>
      <ResponsiveMenuPopup
        visible={responsiveMenuVisible}
        onClose={() => setResponsiveMenuVisible(false)}
      />
    </Container>
  );
};

export default Header;

const Container = styled.div<{ isScrolling: boolean }>`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: transparent;
  transition: background-color 0.3s ease-in-out;
  z-index: 100;

  ${({ isScrolling }) =>
    isScrolling &&
    css`
      background-color: ${palette.WHITE};

      span {
        color: ${palette.DARK_GREY};
      }
    `}
`;

const Navigator = styled(Flex)`
  max-width: 1200px;
  margin: 0 auto;

  @media ${breakPoint.SMALL_DEVICE} {
    display: none;
  }

  @media ${breakPoint.LARGE_DEVICE} {
    padding: 0 24px;
  }
`;

const MenuContainer = styled(Flex)`
  height: 80px;
  gap: 80px;
  @media ${breakPoint.MEDIUM_DEVICE} {
    gap: 40px;
  }
`;

const LogoDiv = styled.div<{ $isMobile: boolean }>`
  svg {
    ${({ $isMobile }) =>
      $isMobile &&
      css`
        width: 60px;
        height: 24px;
      `}
  }
`;

const LinkText = styled(Typography.Text)<{
  active: boolean;
  isScrolling: boolean;
}>`
  display: block;
  padding: 28px 0;
  transition: border-bottom 0.3s ease-in-out;

  ${({ active, isScrolling }) =>
    active &&
    css`
      border-bottom: 2px solid ${isScrolling ? palette.PRIMARY : palette.WHITE};
    `}
`;

const ResponsiveNavigator = styled(Flex)`
  display: none;
  @media ${breakPoint.SMALL_DEVICE} {
    display: flex;
    height: 80px;
    padding: 24px 24px 0px;
  }

  @media ${breakPoint.MOBILE} {
    display: flex;
    height: 56px;
    padding: 16px 24px 0px;
  }
`;
