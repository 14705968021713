import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';

import ArrowRight from 'components/icons/ArrowRight';
import Flex from 'components/ui/Flex';
import Typography from 'components/ui/Typography';
import { breakPoint, palette } from 'shared/styles';
import { PositionKey } from 'type';

const positions: {
  positionKey: PositionKey;
  name: string;
  description: string;
}[] = [
  {
    positionKey: 'it-platform-sales',
    name: '화장품 인허가 IT플랫폼 영업',
    description: '자동화솔루션팀 - 정규직',
  },
  // {
  //   positionKey: 'community-manager',
  //   name: '커뮤니티 관리 매니저',
  //   description: '자동화솔루션팀 - 정규직',
  // },
  // {
  //   positionKey: 'license-manager',
  //   name: '수입 화장품의 인허가 담당자',
  //   description: '자동화솔루션팀 - 정규직',
  // },
  // {
  //   positionKey: 'ui-ux-designer',
  //   name: 'UI/UX 디자이너',
  //   description: '서비스개발팀 - 정규직',
  // },
  // {
  //   positionKey: 'e-commerce-planner',
  //   name: '이커머스(B2B) 웹서비스 기획자',
  //   description: '서비스개발팀 - 정규직',
  // },
  // {
  //   positionKey: 'frontend-developer',
  //   name: '프론트엔드 개발자',
  //   description: '서비스개발팀 - 정규직',
  // },
  {
    positionKey: 'backend-developer',
    name: '백엔드 개발자',
    description: '서비스개발팀 - 정규직',
  },
  // {
  //   positionKey: 'ai-engineer',
  //   name: 'AI 엔지니어',
  //   description: '서비스개발팀 - 정규직',
  // },
  // {
  //   positionKey: 'cosmetic-ra',
  //   name: '화장품 RA 직군',
  //   description: '자동화솔루션팀 - 정규직',
  // },
];

const SectionD = () => {
  const isMobile = useMediaQuery({
    query: breakPoint.MOBILE,
  });
  const isTablet = useMediaQuery({
    query: breakPoint.TABLET,
  });
  const isLD = useMediaQuery({
    query: breakPoint.LARGE_DEVICE,
  });
  const isTabletToLD = useMediaQuery({
    query: breakPoint.TABLET_TO_LARGE_DEVICE,
  });

  return (
    <Container id="positions" isLD={isLD} isTabletToLD={isTabletToLD}>
      <Typography.Title
        css={css`
          ${isMobile &&
          css`
            font-size: 24px;
            line-height: 34px;
          `}
          ${isTabletToLD &&
          css`
            font-size: 36px;
            line-height: 46px;
          `}
        `}
      >
        진행중인 공고
      </Typography.Title>
      <PositionList
        isMobile={isMobile}
        isTablet={isTablet}
        isTabletToLD={isTabletToLD}
      >
        {positions.map((position) => (
          <PositionItem key={position.positionKey} {...position} />
        ))}
      </PositionList>
    </Container>
  );
};

export default SectionD;

const Container = styled.section<{ isLD: boolean; isTabletToLD: boolean }>`
  max-width: 1200px;
  height: auto !important;
  margin: 0 auto;
  padding-top: 200px !important;
  padding-bottom: 200px !important;

  ${({ isLD }) =>
    isLD &&
    css`
      padding: 80px 24px !important;
    `}

  ${({ isTabletToLD }) =>
    isTabletToLD &&
    css`
      padding: 120px 24px !important;
    `}
`;

const PositionList = styled.ul<{
  isMobile: boolean;
  isTablet: boolean;
  isTabletToLD: boolean;
}>`
  margin-top: 88px;

  ${({ isMobile }) =>
    isMobile &&
    css`
      margin-top: 32px;
    `}
  ${({ isTablet }) =>
    isTablet &&
    css`
      margin-top: 56px;
    `}
  ${({ isTabletToLD }) =>
    isTabletToLD &&
    css`
      margin-top: 56px;
    `}
`;

const PositionItem = ({
  positionKey,
  name,
  description,
}: {
  positionKey: PositionKey;
  name: string;
  description: string;
}) => {
  const navigate = useNavigate();
  const handleClickMore = () => {
    navigate(`/job/${positionKey}`);
  };
  const isMobile = useMediaQuery({
    query: breakPoint.MOBILE,
  });
  const isTabletToLD = useMediaQuery({
    query: breakPoint.TABLET_TO_LARGE_DEVICE,
  });

  return (
    <PositionItemContainer
      justify="space-between"
      align="center"
      onClick={handleClickMore}
      isMobile={isMobile}
      isTabletToLD={isTabletToLD}
    >
      <PositionInfoContainer>
        <Typography.Title
          type="s1"
          css={css`
            ${isMobile &&
            css`
              font-size: 18px;
              line-height: 28px;
            `}
            ${isTabletToLD &&
            css`
              font-size: 24px;
              line-height: 34px;
            `}
          `}
        >
          {name}
        </Typography.Title>
        <Typography.Title
          type="b1"
          css={css`
            margin-top: 8px;
            color: #a5aaaf;

            ${isMobile &&
            css`
              font-size: 16px;
              line-height: 24px;
            `}
            ${isTabletToLD &&
            css`
              font-size: 18px;
              line-height: 28px;
            `}
          `}
        >
          {description}
        </Typography.Title>
      </PositionInfoContainer>
      <ArrowRight />
    </PositionItemContainer>
  );
};

const PositionItemContainer = styled(Flex)<{
  isMobile: boolean;
  isTabletToLD: boolean;
}>`
  cursor: pointer;
  padding-bottom: 38px;

  ${({ isMobile }) =>
    isMobile &&
    css`
      padding-bottom: 8px;
    `}
  ${({ isTabletToLD }) =>
    isTabletToLD &&
    css`
      padding-bottom: 24px;
    `}

  border-bottom: 1px solid ${palette.GREY1};

  &:hover {
    border-bottom: 1px solid ${palette.DARK_GREY};
  }

  svg {
    width: 32px;
    height: 32px;

    ${({ isMobile }) =>
      isMobile &&
      css`
        width: 24px;
        height: 24px;
      `}
    path {
      fill: ${palette.DARK_GREY};
    }
  }

  & + & {
    margin-top: 38px;

    ${({ isMobile }) =>
      isMobile &&
      css`
        margin-top: 16px;
      `}
  }
`;
const PositionInfoContainer = styled.div``;
