import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useMediaQuery } from 'react-responsive';

import Typography from 'components/ui/Typography';
import { breakPoint, palette } from 'shared/styles';

const SectionB = () => {
  const isMobile = useMediaQuery({
    query: breakPoint.MOBILE,
  });
  const isTabletToLD = useMediaQuery({
    query: breakPoint.TABLET_TO_LARGE_DEVICE,
  });

  return (
    <Container>
      <Content isMobile={isMobile} isTabletToLD={isTabletToLD}>
        <Typography.Title
          type="h1"
          css={css`
            margin-bottom: 120px;

            ${isMobile &&
            css`
              font-size: 24px;
              line-height: 34px;
              margin-bottom: 48px;
            `}
            ${isTabletToLD &&
            css`
              font-size: 36px;
              line-height: 46px;
              margin-bottom: 56px;
            `}
          `}
        >
          CDRI 조직문화,
          <br />
          CDRI Organizational Culture
        </Typography.Title>
        <MessageContainer isMobile={isMobile} isTabletToLD={isTabletToLD}>
          <Typography.Title type="h2">
            우리는 답을 찾을 것이다. 늘 그랬듯이. Feat.인터스텔라
          </Typography.Title>
          <Typography.Title type="h3">
            We will find a way. We always have. Feat, Interstellar
          </Typography.Title>
        </MessageContainer>
        <MessageContainer isMobile={isMobile} isTabletToLD={isTabletToLD}>
          <Typography.Title type="h2">
            만족은 결과가 아니라 과정에서 옵니다. Feat, 제임스 딘
          </Typography.Title>
          <Typography.Title type="h3">
            The gratification comes in the doing, not in the result. Feat, James
            Dean
          </Typography.Title>
        </MessageContainer>
        <MessageContainer isMobile={isMobile} isTabletToLD={isTabletToLD}>
          <Typography.Title type="h2">
            질문이 없다면 통찰도 없다. Feat, 피커 드러커
          </Typography.Title>
          <Typography.Title type="h3">
            No questions, No insight. Feat, Peter Druker
          </Typography.Title>
        </MessageContainer>
        <MessageContainer isMobile={isMobile} isTabletToLD={isTabletToLD}>
          <Typography.Title type="h2">
            계획 없는 목표는 한낱 꿈에 불가하다. Feat, 생텍쥐페리
          </Typography.Title>
          <Typography.Title type="h3">
            A goal without a plan is just a wish. Feat, Saint-exupery
          </Typography.Title>
        </MessageContainer>
      </Content>
    </Container>
  );
};

export default SectionB;

const Container = styled.section`
  height: auto !important;
  background-color: ${palette.LIGHT_GREY1};
`;

const Content = styled.div<{ isMobile: boolean; isTabletToLD: boolean }>`
  padding: 120px 0px 200px;
  max-width: 1200px;
  margin: 0 auto;

  ${({ isMobile }) =>
    isMobile &&
    css`
      padding: 0px 24px 80px;
    `}

  ${({ isTabletToLD }) =>
    isTabletToLD &&
    css`
      padding: 40px 24px 120px;
    `}
`;

const MessageContainer = styled.div<{
  isMobile: boolean;
  isTabletToLD: boolean;
}>`
  border-bottom: 1px solid #d6d8dd;
  padding-bottom: 42px;

  ${({ isMobile }) =>
    isMobile &&
    css`
      padding-bottom: 8px;
    `}

  ${({ isTabletToLD }) =>
    isTabletToLD &&
    css`
      padding-bottom: 16px;
    `}

  & + & {
    margin-top: 54px;

    ${({ isMobile }) =>
      isMobile &&
      css`
        margin-top: 16px;
      `}

    ${({ isTabletToLD }) =>
      isTabletToLD &&
      css`
        margin-top: 24px;
      `}
  }

  span:nth-of-type(1) {
    ${({ isMobile }) =>
      isMobile &&
      css`
        font-size: 18px;
        line-height: 28px;
      `}

    ${({ isTabletToLD }) =>
      isTabletToLD &&
      css`
        font-size: 24px;
        line-height: 34px;
      `}
  }
  span:nth-of-type(2) {
    color: #c3c7ca;
    margin-top: 24px;

    ${({ isMobile }) =>
      isMobile &&
      css`
        margin-top: 8px;
        font-size: 16px;
        line-height: 24px;
      `}

    ${({ isTabletToLD }) =>
      isTabletToLD &&
      css`
        margin-top: 8px;
        font-size: 18px;
        line-height: 28px;
      `}
  }
`;
