import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import Flex from 'components/ui/Flex';
import Typography from 'components/ui/Typography';
import useIntersectionObserver from 'hooks/useIntersectionObserver';
import { breakPoint } from 'shared/styles';

const SectionB = () => {
  const [isIntersecting, setIsInterSecting] = useState(false);
  const isMobile = useMediaQuery({
    query: breakPoint.MOBILE,
  });
  const isTablet = useMediaQuery({
    query: breakPoint.TABLET,
  });
  const isSmallDevice = useMediaQuery({
    query: breakPoint.SMALL_DEVICE,
  });
  const target = useIntersectionObserver({
    onIntersect: setIsInterSecting,
    repeat: false,
    ...(isMobile && { threshold: 0.1 }),
  });

  return (
    <Container ref={target} isIntersecting={isIntersecting}>
      <StyledFlex direction="column" align="center" justify="center">
        <Typography.Title
          type="h1"
          align="center"
          css={css`
            white-space: pre-line;

            @media screen and ${breakPoint.MOBILE} {
              font-size: 24px;
              line-height: 34px;
            }
            @media screen and ${breakPoint.TABLET} {
              font-size: 36px;
              line-height: 46px;
            }
          `}
        >
          {`AI 기반 화장품 문구${isMobile ? '\n' : ' '}스크리닝 시스템`}
        </Typography.Title>
        <Typography.Title
          type="b1"
          align="center"
          css={css`
            white-space: pre-line;
            margin: 36px 0 80px;

            @media screen and ${breakPoint.MOBILE} {
              font-size: 14px;
              line-height: 22px;
              margin: 32px 0px 36px;
            }
            @media screen and ${breakPoint.TABLET} {
              font-size: 18px;
              line-height: 28px;
              margin: 36px 0px 80px;
            }
          `}
        >
          {`Artwork에서 텍스트 영역을 추출하는 이미지 인식 기술(Image Recognition)과 이미지에서 텍스트를 추출하는${
            isSmallDevice ? ' ' : '\n'
          }광학문자인식(Optical Character Recognition)을 통하여 실제 Artwork의 내용을 추출합니다.${
            isSmallDevice ? ' ' : '\n'
          }Artwork을 AI 분류 모델이 분석하여 인허가에 적합한 내용인지에 대한 추론을 합니다.`}
        </Typography.Title>
        <Image
          src={`https://cdri.s3.ap-northeast-2.amazonaws.com/assets/${
            isMobile ? 'tech-b-mobile' : isTablet ? 'tech-b-tablet' : 'tech-b'
          }.png`}
          alt="ai-diagram"
        />
      </StyledFlex>
    </Container>
  );
};

export default SectionB;

const Container = styled.section<{ isIntersecting: boolean }>`
  position: relative;
  overflow: hidden;
  height: 1204px;

  @media screen and ${breakPoint.LARGE_DEVICE} {
    height: auto;
  }

  span,
  img {
    opacity: 0;
    transition-property: all;
    transition-timing-function: ease-in-out;
    transition-duration: 0.5s;
    transform: translateY(50px);
  }

  ${({ isIntersecting }) =>
    isIntersecting &&
    css`
      span,
      img {
        opacity: 1;
        transform: translateY(0);
      }
      span:nth-of-type(2) {
        transition-delay: 0.3s;
      }

      img {
        transition-delay: 0.6s;
      }
    `}
`;

const StyledFlex = styled(Flex)`
  height: 100%;

  @media screen and ${breakPoint.LARGE_DEVICE} {
    padding: 0 24px;
  }
`;

const Image = styled.img`
  width: 1200px;

  @media screen and ${breakPoint.MOBILE} {
    margin-bottom: 80px;
  }
  @media screen and ${breakPoint.TABLET} {
    margin-bottom: 156px;
  }
  @media screen and ${breakPoint.LARGE_DEVICE} {
    width: 100%;
  }
`;
