import { Route, Routes, useLocation } from 'react-router-dom';
import { useLayoutEffect } from 'react';
import { useSetRecoilState } from 'recoil';

import { isMainScrollingState } from 'store/ui';
import Main from 'components/Main';
import AboutUs from 'components/AboutUs';
import Business from 'components/Business';
import Job from 'components/Job';
import Team from 'components/Team';
import Tech from 'components/Tech';
import CSCenter from 'components/CSCenter';
import JobDetail from 'components/Job/JobDetail';

const RootRoutes = () => {
  const location = useLocation();
  const setIsMainScrolling = useSetRecoilState(isMainScrollingState);
  useLayoutEffect(() => {
    setTimeout(() => {
      setIsMainScrolling(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);
  return (
    <Routes>
      <Route path="/" element={<Main />} />
      <Route path="/about-us" element={<AboutUs />} />
      <Route path="/business" element={<Business />} />
      <Route path="/team" element={<Team />} />
      <Route path="/tech" element={<Tech />} />
      <Route path="/job" element={<Job />} />
      <Route path="/job/:positionKey" element={<JobDetail />} />
      <Route path="/cs" element={<CSCenter />} />
    </Routes>
  );
};

export default RootRoutes;
