import styled from '@emotion/styled';
import { useState } from 'react';
import { css } from '@emotion/react';
import { useMediaQuery } from 'react-responsive';

import Typography from 'components/ui/Typography';
import useIntersectionObserver from 'hooks/useIntersectionObserver';
import { breakPoint, palette } from 'shared/styles';

const SectionB = () => {
  const isSmallDevice = useMediaQuery({
    query: breakPoint.SMALL_DEVICE,
  });
  const messages = [
    {
      title: 'CDRI는 화장품 산업의 디지털화를 목표로 ',
      title2: '시작되었습니다.',
      description: `2017년 화장품 업계에 입문해 화장품 산업 발전에 깊은 관심을 가졌으며, 국내 화장품 산업이${
        isSmallDevice ? ' ' : '\n'
      }더 글로벌화가 되기 위해서는 디지털화가 더 되어야 한다는 생각으로 CDRI를 시작하였습니다.`,
    },
    {
      title: '화장품 산업군에 ',
      title2: '가치를 전달합니다.',
      description: `CDRI는 화장품 데이터 연구소로서, 화장품 산업의 모든 데이터를 접목하여 화장품 산업군에${
        isSmallDevice ? ' ' : '\n'
      }가치를 전달합니다. 화장품 산업의 정보를 편리하게 이용하여 새로운 혁신을 이끌어 낼 수 있으며${
        isSmallDevice ? ' ' : '\n'
      }새로운 혁신은 소비자에게 더 나은 가치를 전달하고 국내 화장품 산업의 성장을 이끌 수 있습니다.`,
    },
    {
      title: '화장품 산업 개발에 지속적으로 투자하고 ',
      title2: '있습니다.',
      description: `화장품 산업 발전에 관심이 많은 팀원분들과 함께 화장품 인증, 네트워크, 컨설팅 플랫폼 개발에 지속적으로${
        isSmallDevice ? ' ' : '\n'
      }투자하고 있습니다. CDRI는 국내 화장품 산업의 디지털화를 통해 정확, 신속, 편리함으로 고객에게 감동, 신뢰,${
        isSmallDevice ? ' ' : '\n'
      }성장가치를 전달하겠습니다.`,
    },
  ];

  return (
    <Container>
      <Typography.Title
        type="h1"
        css={css`
          margin-bottom: 120px;

          @media screen and ${breakPoint.MOBILE} {
            margin-bottom: 36px;
            font-size: 24px;
            line-height: 34px;
            word-break: keep-all;
          }
          @media screen and ${breakPoint.TABLET_TO_LARGE_DEVICE} {
            margin-bottom: 48px;
            font-size: 36px;
            line-height: 46px;
            word-break: keep-all;
          }
        `}
      >
        대표인사말
      </Typography.Title>
      {messages.map((message) => (
        <Message key={message.title} {...message} />
      ))}
      <Typography.Title
        type="b1"
        css={css`
          margin-top: 56px;

          @media screen and ${breakPoint.MOBILE} {
            margin-top: 36px;
            font-size: 14px;
            line-height: 22px;
          }
          @media screen and ${breakPoint.TABLET_TO_LARGE_DEVICE} {
            margin-top: 48px;
            font-size: 16px;
            line-height: 24px;
          }
        `}
      >
        CDRI 대표이사
        <br />
        김준일
      </Typography.Title>
    </Container>
  );
};

export default SectionB;

const Container = styled.section`
  width: 1200px;
  height: 1572px !important;
  margin: 0 auto;
  padding: 200px 0 !important;

  @media screen and ${breakPoint.SMALL_DEVICE} {
    width: 100%;
    height: auto !important;
    padding: 80px 24px !important;
  }
  @media screen and ${breakPoint.TABLET_TO_LARGE_DEVICE} {
    width: 100%;
    height: auto !important;
    padding: 186px 24px !important;
  }
`;

const Message = ({
  title,
  title2,
  description,
}: {
  title: string;
  title2: string;
  description: string;
}) => {
  const [isIntersecting, setIsInterSecting] = useState(false);
  const target = useIntersectionObserver({
    onIntersect: setIsInterSecting,
    repeat: false,
  });
  return (
    <MessageContainer ref={target} isIntersecting={isIntersecting}>
      <div
        css={css`
          display: flex;
          flex-wrap: wrap;
        `}
      >
        <TitleContainer isIntersecting={isIntersecting}>
          <Typography.Title
            type="h2"
            css={css`
              white-space: pre-wrap;
              @media screen and ${breakPoint.MOBILE} {
                font-size: 18px;
                line-height: 28px;
                word-break: keep-all;
              }
              @media screen and ${breakPoint.TABLET_TO_LARGE_DEVICE} {
                font-size: 24px;
                line-height: 34px;
                word-break: keep-all;
              }
            `}
          >
            {title}
          </Typography.Title>
        </TitleContainer>
        <TitleContainer isIntersecting={isIntersecting}>
          <Typography.Title
            type="h2"
            css={css`
              white-space: pre-wrap;
              @media screen and ${breakPoint.MOBILE} {
                font-size: 18px;
                line-height: 28px;
                word-break: keep-all;
              }
              @media screen and ${breakPoint.TABLET_TO_LARGE_DEVICE} {
                font-size: 24px;
                line-height: 34px;
                word-break: keep-all;
              }
            `}
          >
            {title2}
          </Typography.Title>
        </TitleContainer>
      </div>
      <Typography.Title
        type="b1"
        css={css`
          white-space: pre-line;
          margin-top: 34px;

          @media screen and ${breakPoint.MOBILE} {
            margin-top: 16px;
            font-size: 14px;
            line-height: 22px;
            word-break: keep-all;
          }
          @media screen and ${breakPoint.TABLET_TO_LARGE_DEVICE} {
            margin-top: 24px;
            font-size: 16px;
            line-height: 24px;
            word-break: keep-all;
          }
        `}
      >
        {description}
      </Typography.Title>
    </MessageContainer>
  );
};

const MessageContainer = styled.div<{ isIntersecting: boolean }>`
  & + & {
    margin-top: 120px;

    @media screen and ${breakPoint.MOBILE} {
      margin-top: 56px;
    }
    @media screen and ${breakPoint.TABLET_TO_LARGE_DEVICE} {
      margin-top: 48px;
    }
  }
  transform: translateY(50px);
  opacity: 0;
  transition: all 0.5s ease-in-out;

  ${({ isIntersecting }) =>
    isIntersecting &&
    css`
      transform: translateY(0);
      opacity: 1;
    `}

  span[type=h2] {
    position: relative;
    z-index: 1;
  }
`;

const TitleContainer = styled.div<{ isIntersecting: boolean }>`
  position: relative;
  width: fit-content;

  &:after {
    content: '';
    position: absolute;
    top: 28px;
    display: block;
    width: 100%;
    height: 32px;
    background-color: ${palette.LIGHT_GREY2};
    transform: scaleX(0);
    transition: transform 0.5s ease-in-out;
    transition-delay: 0.5s;

    @media screen and ${breakPoint.MOBILE} {
      top: 12px;
      height: 18px;
    }

    @media screen and ${breakPoint.TABLET_TO_LARGE_DEVICE} {
      top: 14px;
      height: 18px;
    }

    ${({ isIntersecting }) =>
      isIntersecting &&
      css`
        transform-origin: left;
        transform: scaleX(1);
      `}
  }
`;
