import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useMemo, useState } from 'react';

import Flex from 'components/ui/Flex';
import FlexItem from 'components/ui/Flex/FlexItem';
import Typography from 'components/ui/Typography';
import useIntersectionObserver from 'hooks/useIntersectionObserver';
import { useMediaQuery } from 'react-responsive';
import { breakPoint, palette } from 'shared/styles';

const histories = [
  {
    date: '2020.11',
    events: ['주식회사 30COS 설립'],
  },
  {
    date: '2021.04',
    events: [
      '인증 자동화 서비스 (30COS) 출시',
      '글로벌 임상시험기관과 MOU 체결',
    ],
  },
  {
    date: '2021.07',
    events: ['프로그램 관련 특허 2건 출원 완료'],
  },
  {
    date: '2021.09',
    events: ['30COS 원료사 버전 출시'],
  },
  {
    date: '2021.11',
    events: ['프로그램 관련 추가 특허 3건 출원 완료'],
  },
  {
    date: '2021.12',
    events: ['‘2021년 충북 블록체인 창업 아이디어 경진대회‘ 대상 수상'],
  },
  {
    date: '2022.01',
    events: ['원료 등록 대행 서비스 개시'],
  },
  {
    date: '2022.03',
    events: ['충북도 인증지원사업 수행기관으로 진행'],
  },
  {
    date: '2022.04',
    events: [
      "'(주) CDRI'로 사명변경",
      'COSBRIDGE 커뮤니티 서비스 출시',
      '인증 자동화 서비스명 BI 변경 (30COS -> CERTICOS)',
    ],
  },
  {
    date: '2023.12',
    events: ['COSMETEST 서비스 출시'],
  },
];

const SectionC = () => {
  const [rightHistories, leftHistories] = useMemo(
    () =>
      histories.reduce(
        (acc, history, index) => {
          acc[index % 2].push(history);
          return acc;
        },
        [[], []] as [typeof histories, typeof histories],
      ),
    [],
  );
  return (
    <Container>
      <Typography.Title
        align="center"
        css={css`
          @media ${breakPoint.MOBILE} {
            font-size: 24px;
            line-height: 34px;
          }
          @media ${breakPoint.TABLET_TO_LARGE_DEVICE} {
            font-size: 36px;
            line-height: 46px;
          }
        `}
      >
        CDRI HISTORY
      </Typography.Title>
      <StyledFlex gap={18}>
        <FlexItem flex="1 1 auto">
          <LeftContainer>
            {leftHistories.map((history, index) => (
              <HistoryItem
                key={index}
                {...history}
                align="right"
                index={index}
              />
            ))}
          </LeftContainer>
        </FlexItem>
        <FlexItem flex="0 0 24px">
          <Line />
        </FlexItem>
        <FlexItem flex="1 1 auto">
          <RightContainer>
            {rightHistories.map((history, index) => (
              <HistoryItem
                key={index}
                {...history}
                align="left"
                index={index}
              />
            ))}
          </RightContainer>
        </FlexItem>
      </StyledFlex>
    </Container>
  );
};

export default SectionC;

const Container = styled.section`
  padding: 200px 0 236px !important;
  height: auto !important;
  background-color: ${palette.LIGHT_GREY};

  @media ${breakPoint.MOBILE} {
    padding: 80px 24px 360px !important;
  }

  @media ${breakPoint.TABLET_TO_LARGE_DEVICE} {
    padding: 120px 24px 400px !important;
  }
`;

const StyledFlex = styled(Flex)`
  width: 1200px;
  margin: 140px auto 0;

  @media ${breakPoint.MOBILE} {
    margin-top: 80px;
    width: 100%;
  }
  @media ${breakPoint.TABLET_TO_LARGE_DEVICE} {
    margin-top: 120px;
    width: 100%;
  }
`;

const LeftContainer = styled.div`
  position: relative;
  top: 132px;
`;

const RightContainer = styled.div`
  position: relative;
  top: -6px;
`;

const Line = () => {
  return (
    <LineContainer>
      {Array.from(Array(10)).map((_, index) => (
        <Node key={index} />
      ))}
    </LineContainer>
  );
};

const LineContainer = styled.div`
  position: relative;

  &:before {
    content: '';
    position: absolute;
    display: block;
    width: 2px;
    height: 1360px;
    left: calc(50% - 1px);
    top: 0;
    background-image: linear-gradient(
      180deg,
      ${palette.PRIMARY} 0%,
      ${palette.PRIMARY} 85%,
      ${palette.LIGHT_GREY} 100%
    );
  }
`;

const Node = () => {
  const [isIntersecting, setIsInterSecting] = useState(false);
  const target = useIntersectionObserver({
    onIntersect: setIsInterSecting,
    repeat: false,
  });
  return <NodeContainer ref={target} isIntersecting={isIntersecting} />;
};

const NodeContainer = styled.div<{ isIntersecting: boolean }>`
  position: relative;
  z-index: 1;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  border: 2px solid ${palette.PRIMARY};
  background-color: ${palette.LIGHT_GREY};
  transform: scale(0);
  animation-duration: 0.7s;
  animation-name: toBig;
  animation-play-state: paused;
  animation-timing-function: ease-in-out;

  & + & {
    margin-top: 114px;
  }

  @keyframes toBig {
    0% {
      transform: scale(0);
    }

    80% {
      transform: scale(1.3);
    }

    100% {
      transform: scale(1);
    }
  }

  ${({ isIntersecting }) =>
    isIntersecting &&
    css`
      animation-play-state: running;
      transform: scale(1);
    `}
`;

const HistoryItem = ({
  date,
  events,
  align,
  index,
}: {
  date: string;
  events: string[];
  align: 'left' | 'right';
  index: number;
}) => {
  const [isIntersecting, setIsInterSecting] = useState(false);
  const target = useIntersectionObserver<HTMLLIElement>({
    onIntersect: setIsInterSecting,
    repeat: false,
  });
  const isMobile = useMediaQuery({
    query: breakPoint.MOBILE,
  });
  return (
    <HistoryItemContainer
      ref={target}
      index={index}
      align={align}
      isIntersecting={isIntersecting}
    >
      <Typography.Title type="s1" align={align}>
        {date}
      </Typography.Title>
      {events.map((event) => (
        <Event
          key={event}
          type={isMobile ? 'b3' : 'b2'}
          align={align}
          style={{ marginTop: 8 }}
        >
          {event}
        </Event>
      ))}
    </HistoryItemContainer>
  );
};

const HistoryItemContainer = styled.li<{
  index: number;
  align: 'left' | 'right';
  isIntersecting: boolean;
}>`
  position: absolute;
  top: ${({ index }) => index * 276}px;

  ${({ align }) =>
    align === 'right' &&
    css`
      right: 0;
    `}

  span[type='b2'] {
    opacity: 0;
    transform: translateY(30px);
    transition: all 0.7s ease-in-out;

    ${({ isIntersecting }) =>
      isIntersecting &&
      css`
        opacity: 1;
        transform: translateY(0);
      `}
  }
`;

const Event = styled(Typography.Title)`
  word-break: keep-all;
  & + & {
    margin-top: 4px;
  }
`;
