export const palette = {
  PRIMARY: '#D51317',
  WHITE: '#FFFFFF',
  BLACK: '#22262B',
  GREY: '#646F7C',
  GREY1: '#D6D8DD',
  GREY2: '#ABAFB8',
  LIGHT_GREY: '#F9F9FB',
  LIGHT_GREY1: '#FAFAFA',
  LIGHT_GREY2: '#F0F0F0',
  BLUE_GREY: '#373F57',
  DARK_GREY: '#53575E',
  SLATE_GREY10: '#F2F4F6',
};

export const breakPoint = {
  MOBILE: `(max-width: 767px)`,
  TABLET: `(min-width: 768px) and (max-width: 820px)`,
  TABLET_LANDSCAPE: `(min-width: 821px) and (max-width: 1024px)`,

  SMALL_DEVICE: `(max-width: 820px)`,
  MEDIUM_DEVICE: `(max-width: 1024px)`,
  LARGE_DEVICE: `(max-width: 1200px)`,
  XL_DEVICE: `(max-width: 1440px)`,

  TABLET_OVER: `(min-width: 821px)`,
  TABLET_TO_MEDIUM_DEVICE: `(min-width: 768px) and (max-width: 1024px)`,
  TABLET_TO_LARGE_DEVICE: `(min-width: 768px) and (max-width: 1200px)`,
  TABLET_TO_XL_DEVICE: `(min-width: 768px) and (max-width: 1440px)`,
};
