import { createRoot } from 'react-dom/client';
import { ThemeProvider } from '@emotion/react';
import { RecoilRoot } from 'recoil';

import './index.css';
import App from './App';
import { palette } from 'shared/styles';

const container = document.getElementById('root');
if (container) {
  const root = createRoot(container);
  root.render(
    <RecoilRoot>
      <ThemeProvider theme={{ palette: palette }}>
        <App />
      </ThemeProvider>
    </RecoilRoot>,
  );
}
