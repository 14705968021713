import styled from '@emotion/styled';

import Text from './Text';

const Title = styled(Text)`
  display: block;
`;

Title.defaultProps = {
  type: 'h1',
};

export default Title;
