import { ReactComponent as Logo } from 'assets/icons/cosmetest-logo.svg';
import BusinessDetail from './BusinessDetail';

const business = {
  title: '더 나은 화장품 시험\n산업을 만듭니다!',
  description: `수천건의 인허가를 진행하면서 자연스럽게 수많은 화장품 시험 성적서를 다루게 되었고, 화장품 시험에 대한 고객들의 다양한 소리를 들을 수 있었습니다. 
  고객의 소리를 들으면서 CDRI는 시험 시장 내 여러 문제점을 확인할 수 
  있었습니다. 고객사들은 불필요한 시험을 하고 있었고, 불필요한 절차로 일이 
  지체되고 있었고, 불필요한 커뮤니케이션을 하고 있었습니다. 시험 가격에 대한 고객의 신뢰도도 떨어지고 있었습니다.
  
  CDRI는 화장품 시험 시장을 디지털 트렌스포메이션 시켜 화장품 시험 시장을 좀 더 효율적이게 만들자는 결심을 하게 되었습니다. 
  특히, 시장 투명성을 지향하여 더 나은 상생을 추구하고 싶었습니다.
  
  CDRI는 시험 기관, 시험 장비, 시험 가격, 국내 시장, 해외 시장, 국가별 규정 등에 대한 데이터 베이스를 구축하고, 해당 데이터에 맞는 시스템과 AI를 
  개발하였습니다. 지금도 cosmetest 는 계속 해서 DB를 확보하고 AI를 
  고도화 하고 있습니다.
  
  cosmetest는 한국에서 OEM/ODM을 진행하는 해외 브랜드들의 시험까지 
  컨설팅해 전체적인 국내 시험 시장을 확대해 나가겠습니다.`,
  link: 'https://www.cosmetest.pro',
  imageUrl:
    'https://cdri.s3.ap-northeast-2.amazonaws.com/assets/business-d.png',
};

const SectionD = () => {
  return <BusinessDetail {...business} logo={<Logo />} />;
};

export default SectionD;
