import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import Typography from 'components/ui/Typography';
import useIntersectionObserver from 'hooks/useIntersectionObserver';
import { breakPoint } from 'shared/styles';

const Solution = ({
  image,
  title,
  message,
}: {
  image: React.ReactNode;
  title: string;
  message: string;
}) => {
  const isMobile = useMediaQuery({ query: breakPoint.MOBILE });
  const isTTLD = useMediaQuery({ query: breakPoint.TABLET_TO_LARGE_DEVICE });
  const [isIntersecting, setIsInterSecting] = useState(false);
  const target = useIntersectionObserver({
    onIntersect: setIsInterSecting,
  });
  return (
    <Container
      ref={target}
      isIntersecting={isIntersecting}
      $isMobile={isMobile}
      isTTLD={isTTLD}
    >
      <ImageContainer>{image}</ImageContainer>
      <Typography.Title
        type="h3"
        align="center"
        css={css`
          margin-top: 24px;

          ${isMobile &&
          css`
            font-size: 18px;
            line-height: 28px;
            word-break: keep-all;
          `}

          ${isTTLD &&
          css`
            margin-top: 16px;
            font-size: 18px;
            line-height: 28px;
            word-break: keep-all;
          `}
        `}
      >
        {title}
      </Typography.Title>
      <Typography.Title
        type="b2"
        align="center"
        color="DARK_GREY"
        css={css`
          word-break: keep-all;
          margin-top: 24px;

          ${isMobile &&
          css`
            font-size: 16px;
            line-height: 24px;
          `}

          ${isTTLD &&
          css`
            margin-top: 16px;
            font-size: 16px;
            line-height: 24px;
          `}
        `}
      >
        {message}
      </Typography.Title>
    </Container>
  );
};

export default Solution;

const Container = styled.div<{
  isIntersecting: boolean;
  $isMobile: boolean;
  isTTLD: boolean;
}>`
  padding: 0 24px;
  flex: 1 1 33.33%;
  margin: 0 auto;
  opacity: 0;
  transform: translateY(50px);
  transition: all 0.5s ease-in-out;

  &:nth-of-type(2) {
    transition-delay: 0.2s;
  }

  &:nth-of-type(3) {
    transition-delay: 0.4s;
  }

  ${({ isIntersecting }) =>
    isIntersecting &&
    css`
      opacity: 1;
      transform: translateY(0);
    `}

  ${({ $isMobile }) =>
    $isMobile &&
    css`
      flex: 1 1 320px;
    `}

  
    ${({ isTTLD }) =>
    isTTLD &&
    css`
      padding: 0px;
    `}
`;

const ImageContainer = styled.div`
  height: 160px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    display: block;
  }
`;
