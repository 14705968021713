import { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';

import { isMainScrollingState } from 'store/ui';
import useIntersectionObserver from './useIntersectionObserver';

const useScroll = () => {
  const setIsScroll = useSetRecoilState(isMainScrollingState);

  const handleIntersect = useCallback(
    (isIntersecting: boolean) => {
      setIsScroll(!isIntersecting);
    },
    [setIsScroll],
  );

  const target = useIntersectionObserver({
    onIntersect: handleIntersect,
    threshold: 0.95,
  });

  return target;
};
export default useScroll;
