import { css } from '@emotion/react';
import { useState } from 'react';
import styled from '@emotion/styled';
import { useMediaQuery } from 'react-responsive';

import Typography from 'components/ui/Typography';
import { ReactComponent as Image1 } from 'assets/images/main-b_1.svg';
import { ReactComponent as Image2 } from 'assets/images/main-b_2.svg';
import { ReactComponent as Image3 } from 'assets/images/main-b_3.svg';
import Solution from './Solution';
import useIntersectionObserver from 'hooks/useIntersectionObserver';
import Flex from 'components/ui/Flex';
import { breakPoint } from 'shared/styles';

const SectionB = () => {
  const isMobile = useMediaQuery({ query: breakPoint.MOBILE });
  const isTablet = useMediaQuery({ query: breakPoint.TABLET });
  const isTTLD = useMediaQuery({ query: breakPoint.TABLET_TO_LARGE_DEVICE });
  const [isIntersecting, setIsIntersecting] = useState(false);
  const target = useIntersectionObserver({
    onIntersect: setIsIntersecting,
    ...(isMobile && { threshold: 0.2 }),
  });

  return (
    <Container ref={target} $isMobile={isMobile} isTTLD={isTTLD}>
      <TitleContainer isIntersecting={isIntersecting}>
        <Typography.Title
          type="h1"
          align="center"
          css={css`
            ${isMobile &&
            css`
              margin-bottom: 36px;
              font-size: 24px;
              line-height: 34px;
            `}

            ${isTTLD &&
            css`
              font-size: 36px;
              line-height: 46px;
            `}
          `}
        >
          CDRI는 화장품 산업의 문제를
          {!isMobile && <br />}
          데이터를 통해 해결합니다
        </Typography.Title>
      </TitleContainer>
      <SolutionContainer
        direction={isMobile ? 'column' : 'row'}
        gap={isTablet ? 26 : 56}
        $isMobile={isMobile}
        isTTLD={isTTLD}
      >
        <Solution
          image={<Image1 />}
          title="화장품 인증 자동화 솔루션"
          message={`비효율적인 화장품 인증 프로세스를 개선하기
        위해 CDRI는 화장품 인증 자동화 솔루션
        CERTICOS를 제공합니다.`}
        />
        <Solution
          image={<Image2 />}
          title="화장품 산업 종사자 커뮤니티"
          message={`통합되지 않고 비효율적인 화장품 커뮤니티를
개선하기 위해 CDRI는 화장품 업계 종사자들을
위한 커뮤니티 COSBRIDGE를 제공합니다.`}
        />
        <Solution
          image={<Image3 />}
          title="데이터 기반의 화장품 컨설팅"
          message={`사람의 감에 의존해 결국 돈과 시간을 더 쓰게
만드는 화장품 산업 전반에 걸친 컨설팅을
개선하기 위해 CDRI는 자체 AI, COS.AI를 통해
데이터 기반의 컨설팅 COSGUIDE를 제공합니다.`}
        />
      </SolutionContainer>
    </Container>
  );
};

export default SectionB;

const Container = styled.section<{ $isMobile: boolean; isTTLD: boolean }>`
  max-width: 1200px;
  margin: 0 auto;
  padding: 200px 0 272px !important;

  ${({ $isMobile }) =>
    $isMobile &&
    css`
      padding: 80px 24px !important;
    `}

  ${({ isTTLD }) =>
    isTTLD &&
    css`
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0px 24px !important;
    `}
`;

const TitleContainer = styled.div<{ isIntersecting: boolean }>`
  opacity: 0;
  transition: opacity 0.5s ease-in-out;

  ${({ isIntersecting }) =>
    isIntersecting &&
    css`
      opacity: 1;
    `}
`;

const SolutionContainer = styled(Flex)<{
  $isMobile: boolean;
  isTTLD: boolean;
}>`
  margin-top: 140px;

  ${({ $isMobile }) =>
    $isMobile &&
    css`
      margin-top: 0px;
      flex-wrap: wrap;

      & > div ~ div {
        margin-top: 56px;
      }
    `}

  ${({ isTTLD }) =>
    isTTLD &&
    css`
      margin-top: 80px;
    `}
`;
