import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useState } from 'react';

import Flex from 'components/ui/Flex';
import FlexItem from 'components/ui/Flex/FlexItem';
import Typography from 'components/ui/Typography';
import useIntersectionObserver from 'hooks/useIntersectionObserver';
import { breakPoint } from 'shared/styles';

const TechnologyItem = ({
  title,
  description,
  imageFilename,
}: {
  title: string;
  description: string;
  imageFilename: string;
}) => {
  const [isIntersecting, setIsInterSecting] = useState(false);
  const target = useIntersectionObserver({
    onIntersect: setIsInterSecting,
    repeat: false,
  });
  return (
    <Container ref={target} isIntersecting={isIntersecting}>
      <StyledFlex align="center" gap={56}>
        <FlexImageContainer>
          <Image
            src={`https://cdri.s3.ap-northeast-2.amazonaws.com/assets/${imageFilename}`}
            alt="technology"
          />
        </FlexImageContainer>
        <FlexTitleContainer>
          <Content>
            <Typography.Title
              type="h3"
              css={css`
                @media ${breakPoint.MOBILE} {
                  margin-top: 36px;
                  font-size: 18px;
                  line-height: 28px;
                }
                @media ${breakPoint.TABLET_TO_LARGE_DEVICE} {
                  margin-top: 36px;
                  font-size: 24px;
                  line-height: 34px;
                }
              `}
            >
              {title}
            </Typography.Title>
            <Typography.Title
              type="b1"
              color="DARK_GREY"
              css={css`
                margin-top: 24px;
                white-space: pre-line;

                @media ${breakPoint.MOBILE} {
                  margin-top: 16px;
                  font-size: 14px;
                  line-height: 24px;
                }
                @media ${breakPoint.TABLET_TO_LARGE_DEVICE} {
                  margin-top: 16px;
                  font-size: 18px;
                  line-height: 28px;
                }
              `}
            >
              {description}
            </Typography.Title>
          </Content>
        </FlexTitleContainer>
      </StyledFlex>
    </Container>
  );
};

export default TechnologyItem;

const Container = styled.div<{ isIntersecting: boolean }>`
  &:not(:first-of-type) {
    margin-top: 200px;

    @media ${breakPoint.LARGE_DEVICE} {
      margin-top: 80px;
    }
  }

  &:nth-of-type(2n) > div {
    flex-direction: row-reverse;
  }

  span {
    opacity: 0;
    transform: translateY(50px);
    transition-property: all;
    transition-duration: 0.5s;
    transition-timing-function: ease-in-out;

    &:nth-of-type(2) {
      transition-delay: 0.3s;
    }

    ${({ isIntersecting }) =>
      isIntersecting &&
      css`
        opacity: 1;
        transform: translateY(0);
      `}
  }
`;

const StyledFlex = styled(Flex)`
  @media ${breakPoint.LARGE_DEVICE} {
    flex-wrap: wrap;
  }
`;

const FlexImageContainer = styled(FlexItem)`
  flex: 0 0 624px;

  @media ${breakPoint.LARGE_DEVICE} {
    flex: 0 0 100%;
  }
`;

const FlexTitleContainer = styled(FlexItem)`
  flex: 1 1 auto;
`;

const Content = styled.div``;

const Image = styled.img`
  width: 100%;
`;
