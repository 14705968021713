import PageLayout from 'components/layout/PageLayout';
import SectionA from './SectionA';
import SectionB from './SectionB';
import SectionC from './SectionC';

const Tech = () => {
  return (
    <PageLayout scrollSnap={false}>
      <SectionA />
      <SectionB />
      <SectionC />
    </PageLayout>
  );
};

export default Tech;
