import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';

import Flex from 'components/ui/Flex';
import Typography from 'components/ui/Typography';
import { breakPoint } from 'shared/styles';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    kakao: any;
  }
}

const SectionA = () => {
  const isMobile = useMediaQuery({ query: breakPoint.MOBILE });
  const isMD = useMediaQuery({ query: breakPoint.MEDIUM_DEVICE });
  const isTabletToMD = useMediaQuery({
    query: breakPoint.TABLET_TO_MEDIUM_DEVICE,
  });
  const isTabletToLD = useMediaQuery({
    query: breakPoint.TABLET_TO_LARGE_DEVICE,
  });

  useEffect(() => {
    const mapScript = document.createElement('script');
    mapScript.async = true;
    mapScript.src = `//dapi.kakao.com/v2/maps/sdk.js?appkey=c4ae4174aa182f57eed619b7ca767248&autoload=false&libraries=services`;

    document.head.appendChild(mapScript);

    const onLoadKakaoMap = () => {
      try {
        if (window.kakao) {
          window.kakao.maps.load(() => {
            const hoContainer = document.getElementById('hoMap');
            const boContainer = document.getElementById('boMap');
            const hoPosition = new window.kakao.maps.LatLng(
              36.635939423043126,
              127.32564657502765,
            );
            const boPosition = new window.kakao.maps.LatLng(
              37.4973502733284,
              127.038096510953,
            );
            const hoOptions = {
              // HINT : 지도의 기본 위도, 경도 값 (오송)
              center: hoPosition,
            };
            const boOptions = {
              // HINT : 지도의 기본 위도, 경도 값 (화원빌딩)
              center: boPosition,
            };
            const hoMap = new window.kakao.maps.Map(hoContainer, hoOptions);
            const boMap = new window.kakao.maps.Map(boContainer, boOptions);
            new window.kakao.maps.Marker({
              map: hoMap,
              position: hoPosition,
            });
            new window.kakao.maps.Marker({
              map: boMap,
              position: boPosition,
            });
          });
        } else {
          throw new Error('Invalid KakaoMap');
        }
      } catch {
        console.error('카카오 맵을 불러올 수 없습니다.');
      }
    };
    mapScript.addEventListener('load', onLoadKakaoMap);

    return () => mapScript.removeEventListener('load', onLoadKakaoMap);
  }, []);
  return (
    <Container isTabletToLD={isTabletToLD} isMobile={isMobile}>
      <Typography.Text
        type="h1"
        css={css`
          ${isTabletToMD &&
          css`
            font-size: 36px;
            line-height: 46px;
          `}
          ${isMobile &&
          css`
            font-size: 24px;
            line-height: 34px;
          `}
        `}
      >
        CS CENTER
      </Typography.Text>
      <Flex
        css={css`
          margin-top: 80px;

          ${isTabletToMD &&
          css`
            margin-top: 56px;
          `}

          ${isMobile &&
          css`
            margin-top: 40px;
          `}
        `}
        $wrap
        gap={24}
      >
        <InfoContainer>
          <Typography.Title
            type="b1"
            color="GREY2"
            css={css`
              ${isMobile &&
              css`
                font-size: 14px;
                line-height: 22px;
              `}
            `}
          >
            Tel
          </Typography.Title>
          <Typography.Title
            type="h3"
            css={css`
              margin-top: 8px;

              ${isMobile &&
              css`
                font-size: 16px;
                line-height: 24px;
              `}
            `}
          >
            02-6225-5253
          </Typography.Title>
        </InfoContainer>
        <InfoContainer>
          <Typography.Title
            type="b1"
            color="GREY2"
            css={css`
              ${isMobile &&
              css`
                font-size: 14px;
                line-height: 22px;
              `}
            `}
          >
            Mail
          </Typography.Title>
          <Typography.Title
            type="h3"
            css={css`
              margin-top: 8px;

              ${isMobile &&
              css`
                font-size: 16px;
                line-height: 24px;
              `}
            `}
          >
            info@cdri.pro
          </Typography.Title>
        </InfoContainer>
        <AddressTitle isMobile={isMobile}>
          <Typography.Title
            type="b1"
            color="GREY2"
            css={css`
              ${isMobile &&
              css`
                font-size: 14px;
                line-height: 22px;
              `}
            `}
          >
            Address
          </Typography.Title>
        </AddressTitle>
        <AddressContainer isMD={isMD}>
          <HeadOfficeInfoContainer>
            <Typography.Title
              type="h3"
              css={css`
                min-height: 68px;

                ${isMD &&
                css`
                  min-height: auto;
                `}

                ${isMobile &&
                css`
                  font-size: 16px;
                  line-height: 24px;
                `}
              `}
            >
              (본사) 충북 청주시 흥덕구 오송읍 오송생명 1로 194-25, SB플라자 3층
              A-13호 (28160)
            </Typography.Title>
            <Map id="hoMap" isTabletToMD={isTabletToMD} isMobile={isMobile} />
          </HeadOfficeInfoContainer>
          <BranchOfficeInfoContainer
            isMobile={isMobile}
            isTabletToMD={isTabletToMD}
          >
            <Typography.Title
              type="h3"
              css={css`
                min-height: 68px;

                ${isMD &&
                css`
                  min-height: auto;
                `}

                ${isMobile &&
                css`
                  font-size: 16px;
                  line-height: 24px;
                `}
              `}
            >
              (지사) 서울 강남구 논현로 417 화원빌딩 가든층 A (06246)
            </Typography.Title>
            <Map id="boMap" isTabletToMD={isTabletToMD} isMobile={isMobile} />
          </BranchOfficeInfoContainer>
        </AddressContainer>
      </Flex>
    </Container>
  );
};

export default SectionA;

const Container = styled.section<{ isTabletToLD: boolean; isMobile: boolean }>`
  min-height: calc(100vh - 162px);
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 200px;
  padding-bottom: 200px;

  ${({ isTabletToLD }) =>
    isTabletToLD &&
    css`
      padding: 180px 24px 120px !important;
    `}

  ${({ isMobile }) =>
    isMobile &&
    css`
      padding: 116px 24px 80px !important;
    `}
`;

const InfoContainer = styled.div`
  flex: 1 1 calc(50% - 12px);
`;

const AddressTitle = styled.div<{ isMobile: boolean }>`
  flex: 1 1 100%;
  margin-top: 56px;

  ${({ isMobile }) =>
    isMobile &&
    css`
      margin-top: 32px;
    `}
`;

const AddressContainer = styled(Flex)<{ isMD: boolean }>`
  margin-top: 14px;
  width: 100%;
  column-gap: 24px;

  ${({ isMD }) =>
    isMD &&
    css`
      flex-direction: column;
      margin-top: 8px;
    `}
`;

const HeadOfficeInfoContainer = styled(InfoContainer)``;

const BranchOfficeInfoContainer = styled(InfoContainer)<{
  isTabletToMD: boolean;
  isMobile: boolean;
}>`
  ${({ isTabletToMD }) =>
    isTabletToMD &&
    css`
      margin-top: 56px;
    `}

  ${({ isMobile }) =>
    isMobile &&
    css`
      margin-top: 32px;
    `}
`;

const Map = styled.div<{
  isTabletToMD: boolean;
  isMobile: boolean;
}>`
  flex: 1 1 auto;
  margin-top: 32px;
  height: 320px;

  ${({ isTabletToMD }) =>
    isTabletToMD &&
    css`
      margin-top: 24px;
      height: 50vw;
    `}

  ${({ isMobile }) =>
    isMobile &&
    css`
      margin-top: 16px;
      height: 50vw;
    `}
`;
