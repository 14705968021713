import { Modal } from 'antd';
import styled from '@emotion/styled';

import Flex from 'components/ui/Flex';
import Typography from 'components/ui/Typography';
import Logo from 'components/icons/Logo';
import { ReactComponent as StockImg } from 'assets/images/stock.svg';

const StockModal = ({
  isStockModalOpen,
  setIsStockModalOpen,
  isMobile,
}: {
  isStockModalOpen: boolean;
  setIsStockModalOpen: (isStockModalOpen: boolean) => void;
  isMobile: boolean;
}) => {
  const handleNotShowToday = () => {
    setIsStockModalOpen(false);
    const midnight = new Date();
    midnight.setHours(24, 0, 0, 0);

    window.localStorage.setItem('STOCK_POP', `${midnight.getTime()}`);
  };

  const handleCancel = () => {
    setIsStockModalOpen(false);
  };
  return (
    <Container
      open={isStockModalOpen}
      onCancel={handleCancel}
      width={480}
      centered
      closeIcon={null}
      footer={null}
    >
      <BodyContainer isMobile={isMobile}>
        <Logo color={'NORMAL'} />
        <Typography.Text
          style={{
            fontSize: isMobile ? 24 : 34,
            fontWeight: 500,
            marginTop: isMobile ? 8 : 16,
            lineHeight: 1.4,
          }}
        >
          신주발행 공고
        </Typography.Text>
        <DescText isMobile={isMobile} style={{ marginTop: 16 }}>
          당 사는 이사회 결의로 아래와 같이 신 주식을 발행하기로
          {isMobile && <br />}&nbsp;하였으므로&nbsp;
          {!isMobile && <br />}
          상법 제418조 4항에 의거 이를 공고합니다.
        </DescText>
        <DescText
          isMobile={isMobile}
          style={{
            lineHeight: '20px',
            marginTop: isMobile ? 16 : 40,
            marginBottom: isMobile ? 8 : 56,
          }}
        >
          -&nbsp; 아 &nbsp;&nbsp; 래 &nbsp;-
        </DescText>
        <DescText isMobile={isMobile} style={{ textAlign: 'start' }}>
          1. 신 주식의 종류와 수 : 기명식 전환우선주식 37주
          <br />
          2. 신 주식의 발행가액 : 1주 금 16,000,000원
          <br />
          3. 납입기일 : 2024년 8월 23일
          <br />
          4. 신 주식의 인수방법 : 정관 제9조 2항 규정에 의한 제3자 배정
          <br />
          5. 현물출자에 관한 사항 : 해당없음
          <br />
          6. 기타 신주발행에 대한 부수적인 사항은 대표이사에게 위임함
        </DescText>
        <DescText isMobile={isMobile} style={{ marginTop: isMobile ? 16 : 40 }}>
          2024년 8월 8일
        </DescText>
        <DescText isMobile={isMobile} style={{ marginTop: isMobile ? 8 : 16 }}>
          주식회사 씨디알아이
          <br />
          충청북도 청주시 흥덕구 오송읍 오송생명1로 194-25,
          <br />
          에스비플라자 3층 에이-13호
          <br />
          대표이사 김준일
        </DescText>
      </BodyContainer>
      <StockImgDiv>
        <StockImg style={{ zIndex: 0 }} />
      </StockImgDiv>
      <Footer align="center" justify="center" style={{ cursor: 'pointer' }}>
        <Flex
          justify="center"
          style={{ width: '50%' }}
          onClick={handleNotShowToday}
        >
          <Typography.Text
            style={{
              fontSize: 14,
              color: '#222',
              textAlign: 'center',
              paddingTop: isMobile ? '16px' : '18px',
              paddingBottom: isMobile ? '16px' : '18px',
            }}
          >
            오늘 하루 보지 않기
          </Typography.Text>
        </Flex>
        <Flex
          justify="center"
          style={{
            width: '50%',
            borderLeft: '1px solid #E7E7E7',
          }}
          onClick={handleCancel}
        >
          <Typography.Text
            style={{
              fontSize: 14,
              color: '#222',
              textAlign: 'center',
              paddingTop: isMobile ? '16px' : '18px',
              paddingBottom: isMobile ? '16px' : '18px',
            }}
          >
            닫기
          </Typography.Text>
        </Flex>
      </Footer>
    </Container>
  );
};

const Container = styled(Modal)`
  .ant-modal-content {
    border: none;
    border-radius: 16px;
    padding: 0 !important;
  }
  .ant-modal-body {
    background-color: #f8f8f8;
    border-radius: 16px;
    text-align: center;
  }
  position: relative;
`;

const BodyContainer = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${({ isMobile }) => (isMobile ? `32px 16px` : `56px 0`)};
  font-family: 'Pretendard';
`;

const Footer = styled(Flex)`
  background-color: white;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  border-top: 1px solid #e7e7e7;
  position: relative;
  z-index: 1;
`;

const DescText = styled(Typography.Text)<{ isMobile: boolean }>`
  font-size: ${({ isMobile }) => (isMobile ? '12px' : '14px')};
  color: #4e5968;
  text-align: center;
  line-height: 1.5;
  z-index: 1;
`;

const StockImgDiv = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 0;
`;

export default StockModal;
