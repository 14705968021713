import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Fragment, useEffect, useLayoutEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useParams } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { useSetRecoilState } from 'recoil';

import { ReactComponent as ArrowLeft } from 'assets/icons/circle-arrow-left.svg';
import PageLayout from 'components/layout/PageLayout';
import Flex from 'components/ui/Flex';
import Typography from 'components/ui/Typography';
import { breakPoint, palette } from 'shared/styles';
import { isMainScrollingState } from 'store/ui';
import { PositionKey } from 'type';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    kakao: any;
  }
}

const positionMap: {
  [key in PositionKey]: {
    title: string;
    team: string;
    type: '정규직' | '비 정규직';
    mainTasks: string[];
    qualifications: string[];
    preferentials?: string[];
    isDeveloper?: boolean;
    year?: string;
  };
} = {
  'it-platform-sales': {
    title: '화장품 인허가 IT플랫폼 영업',
    team: '자동화솔루션팀',
    type: '정규직',
    mainTasks: [
      '• 화장품 국내/해외 인증 서비스 영업',
      '• 화장품 국내/해외 인증 자동화 프로그램 사용 및 교육',
      '• 인증 대행 및 수행 커뮤니케이션',
      '• 데이터 기반의 화장품 컨설팅',
    ],
    qualifications: [
      '• 학력 : 대졸 이상(2,3년)',
      '• 경력 : 3년 ~ 10년(팀원급)',
    ],
    preferentials: [
      '• 화장품 브랜드사 제품교육 강사 경력자',
      '• 화장품 브랜드사 CS 담당 경력자',
      '• 화장품 브랜드사 및 제조사, 부자재사 영업 경력자',
      '• IT플랫폼 고객유치 영업 경력',
    ],
    year: '3년 ~ 10년(팀원급)',
  },
  'community-manager': {
    title: '커뮤니티 관리 매니저',
    team: '자동화솔루션팀',
    type: '정규직',
    mainTasks: [
      '• 화장품 종사자 커뮤니티 서비스 운영 및 관리',
      '• 커뮤니티 운영 정책 수립',
      '• 커뮤니티 활성화를 위한 이벤트 기획 및 아이디어 실행',
      '• 게시물 모니터링 및 고객 CS 응대',
      '• 세션 관리',
    ],
    qualifications: [
      '• 커뮤니티 또는 그에 상응하는 서비스 관리 유경험자',
      '• 뛰어난 의사소통 및 작문 능력을 가지신 분',
      '• 경력 : 5년 ~ 15년(팀원급)',
    ],
    preferentials: [
      '• 화장품 관련 지식이 있으신 분',
      '• 스타트업 근무 경험자',
      '• 주도적이고 긍정적인 마인드의 소유자',
    ],
    year: '5년 ~ 15년(팀원급)',
  },
  'license-manager': {
    title: '수입 화장품의 인허가 담당자',
    team: '자동화솔루션팀',
    type: '정규직',
    mainTasks: [
      '• 해외 화장품 수입 업무 진행 / 표준통관예정보고 업무 진행, 대한화장품협회 CFS 발급',
      '• 화장품 해외 인증 규제 데이터베이스화 및 대응 / 화장품 해외 인증 자동화 프로그램 사용 및 교육',
    ],
    qualifications: [
      '• 학력 : 대졸 이상(2,3년)',
      '• 경력 : 3년 ~ 10년(팀원급)',
    ],
    year: '3년 ~ 10년(팀원급)',
  },
  'ui-ux-designer': {
    title: 'UI/UX 디자이너',
    team: '서비스개발팀',
    type: '정규직',
    mainTasks: [
      '• certicos 신규 웹 서비스 UI/UX 디자인',
      '• certicos 화장품 인증 B2B 서비스 UI/UX 디자인 고도화',
    ],
    qualifications: [
      '• 상용화된 웹 / 앱 서비스의 UI/UX 디자인 경험이 있으신 분',
      '• Sketch, Zeplin 등의 디자인관련 툴 사용가능하신 분',
      '• 협업을 중요시하며 유연한 커뮤니케이션 능력을 지니신 분',
      '• 사용자 관점에서 능동적으로 솔루션을 제시할 수 있는 분',
    ],
    preferentials: [
      '• 적극적이고 주도적으로 서비스를 기획한 경험이 있으신 분',
      '• 구글/애플 디자인 가이드 라인에 대한 깊은 이해도가 있으신 분',
      '• 변화하는 상황 속에서 유연하고 능동적으로 문제를 해결할 수 있는 분',
      '• 서비스의 초기 UI/UX설계부터 출시, 운영 경험이 있으신 분',
    ],
  },
  'e-commerce-planner': {
    title: '이커머스(B2B) 웹서비스 기획자',
    team: '서비스개발팀',
    type: '정규직',
    mainTasks: ['• 신규 이커머스(B2B) 웹서비스 기획'],
    qualifications: [
      '• 상용화된 e커머스 웹서비스 기획 경험이 있으신 분',
      '• 신사업 기획 경험이 있으신 분',
      '• 체계적인 기획 문서 정리가 가능하신 분',
      '• 협업에 적극적이고, 커뮤니케이션을 유연하게 잘 하시는 분',
      '• 경력 : 8년 ~ 15년(팀원급)',
    ],
    preferentials: [
      '• 서비스의 초기 설계부터 기획, 출시, 운영 경험이 있으신 분',
      '• 서비스와 함께 성장하길 원하시는 분',
    ],
  },
  'frontend-developer': {
    title: '프론트엔드 개발자',
    team: '서비스개발팀',
    type: '정규직',
    mainTasks: [
      '• certicos PC / 모바일 웹 프론트엔드 개발 및 지속적인 업데이트 관리',
      '• 화장품 인증 B2B 서비스 고도화 개발, 운영',
      '• 제조사/원료사 B2B 서비스 고도화 개발, 운영',
    ],
    qualifications: [
      '• React, Redux등을 활용한 개발 경험이 풍부하신 분',
      '• HTML5, CSS3, Javascript, Jquery등 관련 기술에 능숙하신 분',
      '• 새로운 기술에 적극적이고 능동적인 분',
      '• 협업에 적극적이고, 커뮤니케이션을 유연하게 잘 하시는 분',
    ],
    preferentials: [
      '• 웹 접근성 / 웹 표준 / 크로스브라우징에 대한 이해가 높으신 분',
      '• 반응형 웹 개발 경험이 풍부하신 분',
      '• 변화하는 상황 속에서 유연하고 능동적으로 문제를 해결할 수 있는 분',
      '• 서비스의 초기 설계부터 개발, 출시, 운영의 경험이 있으신 분',
    ],
    isDeveloper: true,
  },
  'backend-developer': {
    title: '백엔드 개발자',
    team: '서비스개발팀',
    type: '정규직',
    mainTasks: [
      '• certicos 신규 웹 / 앱 서비스 레거시 없는 백엔드 개발',
      '• 화장품 인증 B2B 서비스 고도화 개발, 운영',
      '• 제조사/원료사 B2B 서비스 고도화 개발, 운영',
    ],
    qualifications: [
      '• 상용화된 웹 / 앱 서비스 백엔드 개발 경험이 있으신 분',
      '• Java Springboot Framework을 사용한 개발 경험이 풍부하신 분',
      '• MySQL, MariaDB 사용 경험이 있으신 분',
      '• 새로운 기술에 적극적이고 능동적인 분',
      '• 협업에 적극적이고, 커뮤니케이션을 유연하게 잘 하시는 분',
    ],
    preferentials: [
      '• AWS등 Cloud 기반 서비스 운영 경험 있으신 분',
      '• Redis, MongoDB등 NoSQL 사용 경험',
      '• 데이터베이스 모델링 & 쿼리 튜닝이 가능하신 분',
      '• 대용량 데이터 분석 경험이 있으신 분',
      '• 변화하는 상황 속에서 유연하고 능동적으로 문제를 해결할 수 있는 분',
      '• 서비스의 초기 설계부터 개발, 출시, 운영의 경험이 있으신 분',
    ],
    isDeveloper: true,
    year: '3년 ~ 5년',
  },
  'ai-engineer': {
    title: 'AI 엔지니어',
    team: '서비스개발팀',
    type: '정규직',
    mainTasks: [
      '• 글로벌 화장품 규제 데이터를 통한 분류/예측 등의 AI 알고리즘 개발',
      '• 인증관련 글로벌 빅데이터 수집/가공/처리 수행',
    ],
    qualifications: [
      '• AI관련 상용화된 웹 / 앱 서비스 개발 경험이 있으신 분',
      '• 딥러닝 프레임워크 활용에 능숙하신 분(Pytorch, Tensorflow 등)',
      '• 업무 분야와 관련된 논문을 분석하고 구현, 검증하는 것에 관심 많으신 분',
      '• 새로운 기술에 적극적이고 능동적인 분',
      '• 협업에 적극적이고, 커뮤니케이션을 유연하게 잘 하시는 분',
    ],
    preferentials: [
      '• 인공지능 관련 학과 석사 이상 우대',
      '• 머신러닝/딥러닝 분야 경력 3년 이상 우대',
      '• 다국어 자연어 처리 가능하신 분 우대',
    ],
    isDeveloper: true,
  },
  'cosmetic-ra': {
    title: '화장품 RA 직군',
    team: '자동화솔루션팀',
    type: '정규직',
    mainTasks: [
      `<b>• 화장품 RA(제조사/원료사) 0명</b>
        - 제조사 업무 영역에 대한 자동화 프로세스 설계, 완제품 제조 관련 서류 작성
        - 완제품 품질 서류 관리, 국가별 화장품 규제 대응(REACH),

<b>• 화장품 RA(식약처/한국의약품수출입협회) 0명</b>
        - 화장품 해외 인증 서류 관리, 화장품 해외 인증 진행(유럽, 중국, 인도 동남아 등)
        - 변호사, 외교부, 대사관 서류 확인 및 공증, 대한화장품협회 CFS 발급
        - 화장품 해외 인증 자동화 프로그램 사용 및 교육, 화장품 해외 인증 규제 대응
        - 국가별 화장품 규제 데이터베이스화`,
    ],
    qualifications: ['• 학력 : 무관', '• 경력 : 3년 ~ 10년(팀원급)'],
  },
};

const JobDetail2 = () => {
  const { positionKey } = useParams<{ positionKey: PositionKey }>();
  const setIsScroll = useSetRecoilState(isMainScrollingState);
  useLayoutEffect(() => {
    setTimeout(() => {
      setIsScroll(true);
    }, 100);
  }, [setIsScroll]);
  const isMobile = useMediaQuery({
    query: breakPoint.MOBILE,
  });
  const isTabletToLD = useMediaQuery({
    query: breakPoint.TABLET_TO_LARGE_DEVICE,
  });
  const isLD = useMediaQuery({
    query: breakPoint.LARGE_DEVICE,
  });

  useEffect(() => {
    const mapScript = document.createElement('script');
    mapScript.async = true;
    mapScript.src = `//dapi.kakao.com/v2/maps/sdk.js?appkey=c4ae4174aa182f57eed619b7ca767248&autoload=false&libraries=services`;

    document.head.appendChild(mapScript);

    const onLoadKakaoMap = () => {
      try {
        if (window.kakao) {
          window.kakao.maps.load(() => {
            const boContainer = document.getElementById('boMap');
            const boPosition = new window.kakao.maps.LatLng(
              37.4973502733284,
              127.038096510953,
            );

            const boOptions = {
              // HINT : 지도의 기본 위도, 경도 값 (화원빌딩)
              center: boPosition,
            };
            const boMap = new window.kakao.maps.Map(boContainer, boOptions);

            new window.kakao.maps.Marker({
              map: boMap,
              position: boPosition,
            });
          });
        } else {
          throw new Error('Invalid KakaoMap');
        }
      } catch {
        console.error('카카오 맵을 불러올 수 없습니다.');
      }
    };
    mapScript.addEventListener('load', onLoadKakaoMap);

    return () => mapScript.removeEventListener('load', onLoadKakaoMap);
  }, []);

  if (!positionKey || !Object.keys(positionMap).includes(positionKey)) {
    return (
      <PageLayout scrollSnap={false}>
        <Container isLD={isLD}>잘못된 접근입니다</Container>
      </PageLayout>
    );
  }
  const { title, team, type, mainTasks, qualifications, preferentials, year } =
    positionMap[positionKey];

  return (
    <PageLayout scrollSnap={false}>
      <Container isLD={isLD}>
        <StyledFlex isLD={isLD} dir={!isLD ? 'row' : 'column'}>
          <LeftContainer>
            {!isLD && (
              <Header isMobile={isMobile} isTabletToLD={isTabletToLD}>
                <Typography.Title
                  type={isMobile ? 'h3' : isTabletToLD ? 'h2' : 'h1'}
                >
                  {title}
                </Typography.Title>
                <Typography.Title
                  css={css`
                    font-size: 24px;
                    font-weight: 400;
                    line-height: 34px;
                    margin-top: 16px;
                    color: #393e43;

                    ${isMobile &&
                    css`
                      margin-top: 8px;
                      font-size: 16px;
                      line-height: 28px;
                    `}
                    ${isTabletToLD &&
                    css`
                      margin-top: 8px;
                    `}
                  `}
                >
                  {team} - {type}
                </Typography.Title>
              </Header>
            )}
            <Content dir="column">
              <Subtitle
                isMobile={isMobile}
                type="h3"
                isTabletToLD={isTabletToLD}
              >
                🏢CDRI를 소개해요
              </Subtitle>
              <Typography.Title
                type="b2"
                color="DARK_GREY"
                css={css`
                  white-space: pre-line;

                  ${isMobile &&
                  css`
                    font-size: 14px;
                    line-height: 22x;
                  `};
                `}
              >
                CDRI는 화장품 인증과 관련된 솔루션을 자체 개발하고 있습니다.
                좋은 제품을 빨리 알릴 수 없을까 고민하다
                <br />
                수출이 가장 빠른 길 중에 하나라는 것을 알 수 있었습니다. 수출을
                하려고 인증을 하다 보니 국가마다
                <br />
                법도 다르고 에이전시마다 요구하는 서류도 달랐습니다.
                <br />이 모든 절차가 비효율적이라는 것을 깨닫게 되었고, 질문을
                하게 됩니다.
                <br />
                ‘왜 인증을 이렇게 밖에 할 수 없을까?’ 하고요.
                <br />
                분명 저희와 같은 고민을 하고 있는 화장품 제조사 또는
                브랜드사들이 있을 것이라고 생각합니다.
                <br />
                그래서 저희는 certicos라는 인증 프로그램을 개발하게 되었습니다.
                <br />
                이제 우리는 인증에 대해 다른 시각으로 바라보아야 합니다.
                <br />
                자동화 인증 이것이 certicos의 핵심입니다.
              </Typography.Title>
              {team === '서비스개발팀' && (
                <Fragment>
                  <Subtitle
                    isMobile={isMobile}
                    type="h3"
                    isTabletToLD={isTabletToLD}
                  >
                    🖥합류하게 될 팀을 소개해요
                  </Subtitle>
                  <Typography.Title
                    type="b2"
                    color="DARK_GREY"
                    css={css`
                      white-space: pre-line;

                      ${isMobile &&
                      css`
                        font-size: 14px;
                        line-height: 22x;
                      `};
                    `}
                  >
                    • 애자일 프로세스 기반 프로젝트 진행
                    <br /> • 매일 아침 스탠드업 미팅 진행 <br />• 온/오프라인
                    칸반 진행 <br />• 테크 이슈를 상호 공유하는 주간 테크톡 진행
                    <br /> • 주기적인 코드 리뷰 진행
                  </Typography.Title>
                </Fragment>
              )}
              <Subtitle
                isMobile={isMobile}
                type="h3"
                isTabletToLD={isTabletToLD}
              >
                📑합류하면 함께 할 업무에요
              </Subtitle>
              {mainTasks.map((task) => (
                <Description
                  isMobile={isMobile}
                  isTabletToLD={isTabletToLD}
                  type="b2"
                  key={task}
                  dangerouslySetInnerHTML={{ __html: task }}
                ></Description>
              ))}
              <Subtitle
                type="h3"
                isMobile={isMobile}
                isTabletToLD={isTabletToLD}
              >
                💡이런 분과 함께 하고 싶어요
              </Subtitle>
              {qualifications
                .concat([
                  '• 수평적으로 의견을 말하고 최종 결정은 빠르게 잘 수행할 분',
                  '• 빠르게 잦은 변화에 적응하고 과감하게 실행 할 수 있는 분',
                  '• 성장에 발 맞춰 변화하는 체계를 잡아갈 수 있는 분',
                  '• 책임감을 가지고 업무를 수행할 수 있는 분',
                ])
                .map((qualification) => (
                  <Description
                    type="b2"
                    key={qualification}
                    isMobile={isMobile}
                    isTabletToLD={isTabletToLD}
                  >
                    {qualification}
                  </Description>
                ))}
              {preferentials && (
                <Fragment>
                  <Subtitle
                    isMobile={isMobile}
                    type="h3"
                    isTabletToLD={isTabletToLD}
                  >
                    👍이런 경험이 있다면 더 좋아요
                  </Subtitle>
                  {preferentials.map((preferential) => (
                    <Description
                      type="b2"
                      key={preferential}
                      isMobile={isMobile}
                      isTabletToLD={isTabletToLD}
                    >
                      {preferential}
                    </Description>
                  ))}
                </Fragment>
              )}
              <Subtitle
                isMobile={isMobile}
                type="h3"
                isTabletToLD={isTabletToLD}
              >
                🎉이렇게 합류해요
              </Subtitle>
              <Description
                type="b2"
                isMobile={isMobile}
                isTabletToLD={isTabletToLD}
              >
                • 지원서류 : 자유양식의 이력서(필수), 포트폴리오(선택){' '}
              </Description>
              <Description
                type="b2"
                isMobile={isMobile}
                isTabletToLD={isTabletToLD}
              >
                • 진행절차 :{' '}
                <Typography.Text
                  weight={700}
                  type={isMobile ? 'b3' : 's2'}
                  color="DARK_GREY"
                  css={css`
                    word-break: keep-all;
                  `}
                >
                  서류 전형 &gt;
                  {title.includes('개발자') && ' 과제 전형 >'} 1차(실무) 인터뷰
                  &gt; 2차(임원) 인터뷰 &gt; 처우협의 &gt; 최종합격
                </Typography.Text>
              </Description>
              <Description
                type="b2"
                isMobile={isMobile}
                isTabletToLD={isTabletToLD}
              >
                • CDRI 및 지원자의 상황에 따라 전형 절차는 달라질 수 있습니다.
              </Description>
              <Subtitle
                isMobile={isMobile}
                type="h3"
                isTabletToLD={isTabletToLD}
              >
                ✔️참고해 주세요
              </Subtitle>
              <Description
                type="b2"
                isMobile={isMobile}
                isTabletToLD={isTabletToLD}
              >
                • 3개월의 수습기간이 있습니다.
              </Description>
              <Description
                type="b2"
                isMobile={isMobile}
                isTabletToLD={isTabletToLD}
              >
                • 입사 지원 서류에서 허위기재 사실이 발견될 경우, 입사가 취소될
                수 있습니다.
              </Description>
              <Description
                type="b2"
                isMobile={isMobile}
                isTabletToLD={isTabletToLD}
              >
                • 직무 또는 상황에 따라 전형 절차는 변경될 수 있습니다.
              </Description>
              <Description
                type="b2"
                isMobile={isMobile}
                isTabletToLD={isTabletToLD}
              >
                • 국가유공자 및 장애인 등 취업보호대상자는 관계 법령에 의거하여
                우대합니다.
              </Description>
              <Subtitle
                isMobile={isMobile}
                type="h3"
                isTabletToLD={isTabletToLD}
              >
                🌟복지 및 근무환경을 소개해요
              </Subtitle>
              <Description
                type="b2"
                isMobile={isMobile}
                isTabletToLD={isTabletToLD}
              >
                <b>몰입하고 성장할 수 있는 근무 환경</b>
              </Description>
              <SubDescription
                type={isMobile ? 'b3' : 'b2'}
                style={{ marginTop: 8 }}
              >
                • 유연출퇴근제 8시~10시
              </SubDescription>
              <SubDescription type={isMobile ? 'b3' : 'b2'}>
                • 자유로운 복장, 모자에 반바지도 당연히 가능!
              </SubDescription>
              <SubDescription type={isMobile ? 'b3' : 'b2'}>
                • 자유로운 연차 2022년 연차 사용율 98%
              </SubDescription>
              <SubDescription type={isMobile ? 'b3' : 'b2'}>
                • 개발직군 원하는 고사양 장비 제공 (맥북프로, 듀얼 모니터 등)
              </SubDescription>
              <SubDescription type={isMobile ? 'b3' : 'b2'}>
                • 도서지원금 월 50,000원 & 자유로운 책 리뷰 공유
              </SubDescription>
              <SubDescription type={isMobile ? 'b3' : 'b2'}>
                • 온오프 교육 & 헬스케어 등 개인 성장지원금 60만원
              </SubDescription>
              <Description
                type="b2"
                css={css`
                  margin-top: 24px;

                  ${isMobile &&
                  css`
                    margin-top: 32px;
                  `}
                  ${isTabletToLD &&
                  css`
                    margin-top: 48px;
                  `}
                `}
                isMobile={isMobile}
                isTabletToLD={isTabletToLD}
              >
                <b>건강을 위한 충분한 휴식</b>
              </Description>
              <SubDescription
                style={{ marginTop: 8 }}
                type={isMobile ? 'b3' : 'b2'}
              >
                • CDRI Only 추가 연차+법정기준연차까지 최대 19개 연차
              </SubDescription>
              <SubDescription type={isMobile ? 'b3' : 'b2'}>
                • 기념일 히든 연차 (결혼, 본인 생일 등)
              </SubDescription>
              <SubDescription type={isMobile ? 'b3' : 'b2'}>
                • 경조금과 경조휴가 지원
              </SubDescription>
              <SubDescription type={isMobile ? 'b3' : 'b2'}>
                • 장기 근속 휴가 2년 5일, 4년 15일, 6년 20일
              </SubDescription>
              <Description
                type="b2"
                css={css`
                  margin-top: 24px;

                  ${isMobile &&
                  css`
                    margin-top: 32px;
                  `}
                  ${isTabletToLD &&
                  css`
                    margin-top: 48px;
                  `}
                `}
                isMobile={isMobile}
                isTabletToLD={isTabletToLD}
              >
                <b>좋은 인간관계</b>
              </Description>
              <SubDescription
                type={isMobile ? 'b3' : 'b2'}
                style={{ marginTop: 8 }}
              >
                • 사내 조직문화 서베이 결과 부동의 장점, 1위 팀워크!!
              </SubDescription>
              <SubDescription type={isMobile ? 'b3' : 'b2'}>
                • 팀의 원활한 소통을 위한 팀 빌딩비 지원
              </SubDescription>
              <SubDescription type={isMobile ? 'b3' : 'b2'}>
                • 방향성 이해+자유롭게 의견 개진하는 타운홀 미팅
              </SubDescription>
            </Content>
          </LeftContainer>
          <RightContainer dir="column">
            {isLD && (
              <Header isMobile={isMobile} isTabletToLD={isTabletToLD}>
                <Typography.Title
                  type={isMobile ? 'h3' : isTabletToLD ? 'h2' : 'h1'}
                >
                  {title}
                </Typography.Title>
                <Typography.Title
                  css={css`
                    font-size: 24px;
                    font-weight: 400;
                    line-height: 34px;
                    margin-top: 16px;
                    color: #393e43;

                    ${isMobile &&
                    css`
                      margin-top: 8px;
                      font-size: 16px;
                      line-height: 28px;
                    `}
                    ${isTabletToLD &&
                    css`
                      margin-top: 8px;
                    `}
                  `}
                >
                  {team} - {type}
                </Typography.Title>
              </Header>
            )}
            <SummaryContainer>
              <Flex
                gap={32}
                style={{
                  borderBottom: `1px solid #E0E2E8`,
                  paddingBottom: '16px',
                }}
              >
                <Typography.Text
                  type="b3"
                  color="BLUE_GREY"
                  style={{ width: 52 }}
                >
                  직군
                </Typography.Text>
                <Typography.Text type="b3" color="BLACK">
                  {title}
                </Typography.Text>
              </Flex>
              <Flex
                gap={32}
                style={{
                  borderBottom: `1px solid #E0E2E8`,
                  padding: '16px 0',
                }}
              >
                <Typography.Text
                  type="b3"
                  color="BLUE_GREY"
                  style={{ width: 52 }}
                >
                  경력사항
                </Typography.Text>
                <Typography.Text type="b3" color="BLACK">
                  {year}
                </Typography.Text>
              </Flex>
              <Flex
                gap={32}
                style={{
                  borderBottom: `1px solid #E0E2E8`,
                  padding: '16px 0',
                }}
              >
                <Typography.Text
                  type="b3"
                  color="BLUE_GREY"
                  style={{ width: 52 }}
                >
                  고용형태
                </Typography.Text>
                <Typography.Text type="b3" color="BLACK">
                  정규직
                </Typography.Text>
              </Flex>
              <Flex
                gap={32}
                style={{
                  padding: '16px 0',
                }}
              >
                <Typography.Text
                  type="b3"
                  color="BLUE_GREY"
                  style={{ width: 52 }}
                >
                  근무지
                </Typography.Text>
                <Typography.Text type="b3" color="BLACK">
                  강남구 논현로 417 화원빌딩 가든층 A
                </Typography.Text>
              </Flex>
              <Map id="boMap" />
            </SummaryContainer>
            {!isLD && (
              <SubmitButton
                onClick={() =>
                  window.open('https://www.wanted.co.kr/wd/186842', '_blank')
                }
              >
                지원하기
              </SubmitButton>
            )}
          </RightContainer>
        </StyledFlex>
        <HashLink to="/job#positions" smooth>
          <Flex
            css={css`
              margin-top: 80px;
              margin-bottom: 120px;
              cursor: pointer;

              ${isMobile &&
              css`
                margin: 24px 0;
              `}
            `}
            gap={isMobile ? 8 : 14}
            align="center"
          >
            <ArrowLeft
              css={css`
                ${isMobile &&
                css`
                  width: 24px;
                  height: 24px;
                `}
                ${isTabletToLD &&
                css`
                  width: 40px;
                  height: 40px;
                `}
              `}
            />
            <Typography.Text
              type="b1"
              css={css`
                ${isMobile &&
                css`
                  font-size: 16px;
                  line-height: 24px;
                `}
                ${isTabletToLD &&
                css`
                  font-size: 18px;
                  line-height: 28px;
                `}
              `}
            >
              목록으로 돌아가기
            </Typography.Text>
          </Flex>
        </HashLink>
        {isLD && (
          <ButtonContainer>
            <SubmitButton
              onClick={() =>
                window.open('https://www.wanted.co.kr/wd/186842', '_blank')
              }
            >
              지원하기
            </SubmitButton>
          </ButtonContainer>
        )}
      </Container>
    </PageLayout>
  );
};

export default JobDetail2;

const Container = styled.section<{ isLD: boolean }>`
  max-width: 1200px;
  margin: 0 auto;
  min-height: 100vh;
  padding-top: 200px;
  padding-bottom: 200px;

  ${({ isLD }) =>
    isLD &&
    css`
      padding: 0 24px !important;
    `}
`;

const LeftContainer = styled(Flex)`
  flex-direction: column;
`;

const RightContainer = styled(Flex)`
  flex-direction: column;
  width: 368px;
  gap: 32px;
  @media ${breakPoint.LARGE_DEVICE} {
    width: 100%;
    margin-top: 116px;
  }
`;

const SummaryContainer = styled(Flex)`
  flex-direction: column;
  border-radius: 8px;
  width: 100%;
  border: 1px solid ${palette.LIGHT_GREY2};
  padding: 16px 24px;

  @media ${breakPoint.LARGE_DEVICE} {
    padding: 24px;
  }
`;

const Header = styled.div<{ isMobile: boolean; isTabletToLD: boolean }>`
  /* ${({ isMobile }) =>
    isMobile &&
    css`
      padding-bottom: 24px;
      margin-top: 116px;
    `}

  ${({ isTabletToLD }) =>
    isTabletToLD &&
    css`
      padding-bottom: 32px;
      margin-top: 180px;
    `} */
`;

const StyledFlex = styled(Flex)<{ isLD: boolean }>`
  justify-content: space-between;
  ${({ isLD }) =>
    isLD &&
    css`
      flex-direction: column-reverse;
      justify-content: start;
    `}
`;

const Content = styled(Flex)`
  flex-direction: column;
`;

const Subtitle = styled(Typography.Title)<{
  isMobile: boolean;
  isTabletToLD: boolean;
}>`
  margin: 56px 0px 16px;

  ${({ isMobile }) =>
    isMobile &&
    css`
      font-size: 18px;
      line-height: 28px;
      margin: 32px 0px 8px;
    `}

  ${({ isTabletToLD }) =>
    isTabletToLD &&
    css`
      font-size: 24px;
      line-height: 34px;
      margin: 48px 0px 16px;
    `}
`;

const Description = styled(Typography.Title)<{
  isMobile: boolean;
  isTabletToLD: boolean;
}>`
  b {
    font-weight: 700;
  }

  ${({ isMobile }) =>
    isMobile &&
    css`
      font-size: 14px;
      line-height: 22px;
    `}

  ${({ isTabletToLD }) =>
    isTabletToLD &&
    css`
      font-size: 16px;
      line-height: 24px;
    `}
`;

const SubDescription = styled(Typography.Title)`
  padding-left: 16px;
  color: ${palette.DARK_GREY};
`;

const ButtonContainer = styled.div`
  background-color: white;
  position: sticky;
  bottom: 0;
  width: 100%;
  padding: 24px 0;
  z-index: 100;
`;

const SubmitButton = styled(Flex)`
  background-color: ${palette.PRIMARY};
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  width: 368px;
  height: 44px;
  color: white;
  cursor: pointer;
  transition: all 200ms ease-in;
  @media ${breakPoint.LARGE_DEVICE} {
    width: 100%;
  }
  &:hover {
    background-color: #c21017;
  }
`;

const Map = styled.div`
  flex: 1 1 auto;
  height: 160px;
`;
