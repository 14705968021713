import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useMediaQuery } from 'react-responsive';

import Flex from 'components/ui/Flex';
import Typography from 'components/ui/Typography';
import useScroll from 'hooks/useScroll';
import { breakPoint } from 'shared/styles';

const SectionA = () => {
  const target = useScroll();
  const isMobile = useMediaQuery({ query: breakPoint.MOBILE });
  const isTTLD = useMediaQuery({ query: breakPoint.TABLET_TO_LARGE_DEVICE });

  return (
    <Container ref={target} $isMobile={isMobile}>
      <video loop autoPlay muted preload="none">
        <source
          src={`//cdri.s3.ap-northeast-2.amazonaws.com/assets/intro-${
            isMobile ? 'mobile' : isTTLD ? 'tablet' : 'web'
          }.mp4`}
          type="video/mp4"
        />
      </video>
      <Flex justify="center" align="center" style={{ height: '100%' }}>
        <Typography.Title
          color="WHITE"
          css={css`
            width: 1200px;
            position: absolute;
            padding: 0 24px;

            ${isMobile &&
            css`
              font-size: 24px;
              line-height: 34px;
              word-break: keep-all;
              width: 100%;
            `}

            ${isTTLD &&
            css`
              width: 100%;
              max-width: 1200px;
              font-size: 36px;
              line-height: 46px;
            `}
          `}
        >
          Create a Better Beauty Industry!
          <br />
          CDRI는 더 나은 뷰티 산업을 만듭니다
        </Typography.Title>
      </Flex>
    </Container>
  );
};

export default SectionA;

const Container = styled.section<{ $isMobile: boolean }>`
  position: relative;
  background-color: #000;
  overflow: hidden;

  video {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    transform: translate(-50%, -50%);
    z-index: 0;
  }

  ${({ $isMobile }) =>
    $isMobile &&
    css`
      height: 100vh;
    `}
`;
