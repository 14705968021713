import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { palette } from 'shared/styles';
import { TypographyProps } from '.';

const Text = styled.span<TypographyProps>`
  color: ${({ color }) => palette[color || 'BLACK']};
  text-align: ${({ align }) => align};

  ${({ type }) =>
    type === 'h1' &&
    css`
      font-size: 48px;
      font-weight: 700;
      line-height: 64px;
    `}
  ${({ type }) =>
    type === 'h2' &&
    css`
      font-size: 36px;
      font-weight: 700;
      line-height: 46px;
    `}
  ${({ type }) =>
    type === 'h3' &&
    css`
      font-size: 24px;
      font-weight: 700;
      line-height: 34px;
    `}
   ${({ type }) =>
    type === 's0' &&
    css`
      font-size: 30px;
      font-weight: 400;
      line-height: 42px;
    `}
   ${({ type }) =>
    type === 's1' &&
    css`
      font-size: 18px;
      font-weight: 700;
      line-height: 28px;
    `}
  ${({ type }) =>
    type === 's2' &&
    css`
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
    `}
  ${({ type }) =>
    type === 's3' &&
    css`
      font-size: 20px;
      font-weight: 700;
      line-height: 32px;
    `}
  ${({ type }) =>
    type === 's2' &&
    css`
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
    `}
  ${({ type }) =>
    type === 'b1' &&
    css`
      font-size: 18px;
      font-weight: 400;
      line-height: 28px;
    `}
  ${({ type }) =>
    type === 'b2' &&
    css`
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    `}
  ${({ type }) =>
    type === 'b3' &&
    css`
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
    `}
  ${({ type }) =>
    type === 'small' &&
    css`
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
    `}
  ${({ weight }) =>
    weight &&
    css`
      font-weight: ${weight};
    `};
`;

Text.defaultProps = {
  type: 'b1',
  align: 'left',
};

export default Text;
