import styled from '@emotion/styled';
import { css } from '@emotion/react';

const FlexItem = styled.div<{
  flex?: React.CSSProperties['flex'];
  order?: React.CSSProperties['order'];
}>`
  flex: ${({ flex }) => flex};
  ${({ order }) =>
    order &&
    css`
      order: ${order};
    `}
`;

FlexItem.defaultProps = {
  flex: '0 0 auto',
};

export default FlexItem;
