import BusinessDetail from './BusinessDetail';
import { ReactComponent as Logo } from 'assets/icons/certicos-logo.svg';

const business = {
  title: '더 싸게, 더 빠르게, 더 정확하게',
  description: `인증을 하다 보니 국가마다 법도 다르고 요구하는 서류도 달랐습니다.
  서류전달 절차가 비효율적이라는 것을 깨닫게 되었고, 질문을 하게 됩니다.
  
  ‘왜 이렇게 밖에 할 수 없을까?’
  분명 저희와 같은 고민을 하고 있는 화장품 브랜드사가 있을 것이라고 생각합니다. 그래서 저희는 인증 자동화 프로그램 CERTICOS를 개발하게 되었습니다.

  빅데이터 기술, 블록체인 기술, AI 기술 등이 탑재된 고도화된 온라인 플랫폼을 통한 자동 인허가 진행으로 더 빠르게 더 저렴하게 더 정확하게 인허가 서비스를 제공합니다.`,
  link: 'https://www.certicos.pro',
  imageUrl:
    'https://cdri.s3.ap-northeast-2.amazonaws.com/assets/business-b.png',
};

const SectionB = () => {
  return <BusinessDetail {...business} logo={<Logo />} />;
};

export default SectionB;
