import styled from '@emotion/styled';

import { breakPoint, palette } from 'shared/styles';
import TechnologyItem from './TechnologyItem';

const technologies = [
  {
    title: '이미지 인식 기술',
    description:
      'PDF인 Artwork을 이미지로 변환한 후, 해상도 조절과 Grayscale 등의 전처리 과정을 거칩니다.\n컴퓨터 비전 분야에서 유용하게 쓰이는 OpenCV의 이미지 인식 기술을 사용하며, 이미지에서 텍스트가 있는 영역을 좌표 값으로 반환합니다.',
    imageFilename: 'tech-c-1.png',
  },
  {
    title: '광학문자인식',
    description:
      '이미지 인식 기술을 통하여 얻은 Artwork 내의 텍스트 영역의 좌표를 활용하여 광학문자인식을 수행합니다.\n광학문자인식의 결과가 AI 분류 모델을 통해 추론할 입력값이 됩니다.',
    imageFilename: 'tech-c-2.png',
  },
  {
    title: 'AI 분류 모델',
    description:
      'CDRI의 AI 분류 모델은 자체 수집 및 태깅한 데이터를 통하여 학습 되어 있으며, 광학문자인식의 결과를 Multi-label Classification 모델의 입력으로 사용합니다.\nArtwork의 문구가 사용이 가능한지, 사용이 금지되어 있는지, 증빙이 필요한지에 대하여 추론하여 빠르고 정확한 인허가를 제공합니다.',
    imageFilename: 'tech-c-3.png',
  },
];

const SectionC = () => {
  return (
    <Container>
      <TechnologyList>
        {technologies.map((technology) => (
          <TechnologyItem key={technology.title} {...technology} />
        ))}
      </TechnologyList>
    </Container>
  );
};

export default SectionC;

const Container = styled.section`
  position: relative;
  padding: 280px 0 !important;
  background-color: ${palette.LIGHT_GREY1};

  @media screen and ${breakPoint.MOBILE} {
    padding: 80px 24px !important;
  }
  @media screen and ${breakPoint.TABLET_TO_LARGE_DEVICE} {
    padding: 120px 24px !important;
  }
`;

const TechnologyList = styled.section`
  width: 1200px;
  margin: 0 auto;

  @media screen and ${breakPoint.LARGE_DEVICE} {
    width: 100%;
  }
`;
