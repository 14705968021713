import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { css } from '@emotion/react';

import Flex from '../Flex';
import { breakPoint } from 'shared/styles';
import Typography from '../Typography';
import Logo from 'components/icons/Logo';
import { ReactComponent as Close } from 'assets/icons/close.svg';

const Container = styled.div<{ visible: boolean }>`
  position: absolute;
  width: 100vw;
  top: 0px;
  left: 0px;
  z-index: 1;
  background-color: #fff;
  box-shadow: 2px 6px 16px rgba(17, 81, 128, 0.08);
  border-radius: 0 0 4px 4px;
  overflow: hidden;

  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  opacity: ${({ visible }) => (visible ? '1' : '0')};
  transform: ${({ visible }) => !visible && `translateY(-100%)`};
  transition: all 0.3s ease-out;

  @media ${breakPoint.TABLET_OVER} {
    display: none;
  }
`;

const Header = styled(Flex)`
  display: flex;
  height: 80px;
  padding: 24px 24px 0px;

  @media ${breakPoint.MOBILE} {
    height: 56px;
    padding: 16px 24px 0px;
  }
`;

const ResponsiveMenuPopup = ({
  visible,
  className,
  onClose,
}: {
  visible: boolean;
  className?: string;
  onClose: () => void;
}) => {
  const isMobile = useMediaQuery({ query: breakPoint.MOBILE });

  return (
    <Container className={className} visible={visible} onClick={onClose}>
      <Header justify="space-between">
        <Link to="/">
          <LogoDiv $isMobile={isMobile}>
            <Logo color="NORMAL" />
          </LogoDiv>
        </Link>
        <Close onClick={onClose} />
      </Header>
      <Flex direction="column">
        <Link to="/about-us">
          <LinkText
            $isMobile={isMobile}
            type={isMobile ? 'b3' : 's2'}
            weight={400}
          >
            ABOUT US
          </LinkText>
        </Link>
        <Link to="/business">
          <LinkText
            $isMobile={isMobile}
            type={isMobile ? 'b3' : 's2'}
            weight={400}
          >
            BUSINESS
          </LinkText>
        </Link>
        <Link to="/team">
          <LinkText
            $isMobile={isMobile}
            type={isMobile ? 'b3' : 's2'}
            weight={400}
          >
            TEAM
          </LinkText>
        </Link>
        <Link to="/tech">
          <LinkText
            $isMobile={isMobile}
            type={isMobile ? 'b3' : 's2'}
            weight={400}
          >
            TECH
          </LinkText>
        </Link>
        <Link to="/job">
          <LinkText
            $isMobile={isMobile}
            type={isMobile ? 'b3' : 's2'}
            weight={400}
          >
            JOB
          </LinkText>
        </Link>
        <Link to="cs">
          <LinkText
            $isMobile={isMobile}
            type={isMobile ? 'b3' : 's2'}
            weight={400}
          >
            CS CENTER
          </LinkText>
        </Link>
      </Flex>
    </Container>
  );
};

export default ResponsiveMenuPopup;

const LinkText = styled(Typography.Text)<{ $isMobile: boolean }>`
  display: block;
  padding: 28px 0px 28px 24px;

  ${({ $isMobile }) =>
    $isMobile &&
    css`
      padding: 21px 0 21px 24px;
    `}
`;

const LogoDiv = styled.div<{ $isMobile: boolean }>`
  svg {
    ${({ $isMobile }) =>
      $isMobile &&
      css`
        width: 60px;
        height: 24px;
      `}
  }
`;
