import { ReactComponent as Logo } from 'assets/icons/cosguide-logo.svg';
import BusinessDetail from './BusinessDetail';

const business = {
  title: '데이터 기반으로 시행착오를\n줄여 비용과 시간을 절약',
  description: `성분 하나 때문에 이미 출시한 제품을 해외에 수출할 수 없었습니다.
  특정 원료의 효능이 나오지 않는 것을 몰라 의도한 제품을 만들 수 없었습니다.
  문구 하나에, 많은 비용을 지불하고 임상 시험을 진행한 적도 있었습니다.
  직접 부딪혀보았기에 더 좋은, 더 나은 가이드를 제시하고 싶었습니다.

  화장품 산업의 빅데이터를 분석하고, 자체 개발한 AI(COS.AI)를 종합하여 Cosguide를 개발했습니다.
  Cosguide는 데이터 기반의 맞춤형 서비스를 제공하여 시간과 비용을 효과적으로 줄일 수 있는 컨설팅 플랫폼입니다.`,
  imageUrl:
    'https://cdri.s3.ap-northeast-2.amazonaws.com/assets/business-e.png',
};

const SectionE = () => {
  return <BusinessDetail {...business} reverse logo={<Logo />} />;
};

export default SectionE;
