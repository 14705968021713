import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import ArrowRight from 'components/icons/ArrowRight';
import Flex from 'components/ui/Flex';
import FlexItem from 'components/ui/Flex/FlexItem';
import Typography from 'components/ui/Typography';
import useIntersectionObserver from 'hooks/useIntersectionObserver';
import { breakPoint, palette } from 'shared/styles';

const BusinessDetail = ({
  reverse = false,
  title,
  description,
  link,
  imageUrl,
  logo,
}: {
  reverse?: boolean;
  title: string;
  description: string;
  link?: string;
  imageUrl: string;
  logo: React.ReactNode;
}) => {
  const [isIntersecting, setIsInterSecting] = useState(false);
  const isTabletToLD = useMediaQuery({
    query: breakPoint.TABLET_TO_LARGE_DEVICE,
  });
  const isMobile = useMediaQuery({ query: breakPoint.MOBILE });
  const isLD = useMediaQuery({ query: breakPoint.LARGE_DEVICE });
  const isSD = useMediaQuery({ query: breakPoint.SMALL_DEVICE });

  const target = useIntersectionObserver({
    onIntersect: setIsInterSecting,
    repeat: false,
    threshold: 0.3,
  });
  const handleClickLink = () => {
    window.open(link, '_blank');
  };

  return (
    <Container isLD={isLD} isMobile={isMobile} isTabletToLD={isTabletToLD}>
      <Flex
        justify="center"
        direction={reverse ? 'row-reverse' : 'row'}
        gap={isTabletToLD ? 32 : 64}
        css={css`
          width: 100%;

          ${isSD &&
          css`
            flex-direction: column-reverse;
          `}
        `}
      >
        <FlexItem
          css={css`
            flex: 1 1 46%;

            ${isSD &&
            css`
              margin-top: 36px;
            `}
          `}
        >
          <Content ref={target} isIntersecting={isIntersecting}>
            <Logo isTabletToLD={isTabletToLD} isMobile={isMobile}>
              {logo}
            </Logo>
            <Typography.Title
              type="h2"
              css={css`
                white-space: pre-line;
                margin-top: 24px;

                ${isTabletToLD &&
                css`
                  font-size: 24px;
                  line-height: 34px;
                  margin-top: 16px;
                `}

                ${isMobile &&
                css`
                  font-size: 18px;
                  line-height: 28px;
                  margin-top: 16px;
                `}
              `}
            >
              {title}
            </Typography.Title>
            <Typography.Title
              type="b2"
              color="DARK_GREY"
              css={css`
                white-space: pre-line;
                margin-top: 30px;
                word-break: keep-all;

                ${isTabletToLD &&
                css`
                  margin-top: 24px;
                `}

                ${isMobile &&
                css`
                  font-size: 14px;
                  line-height: 22px;
                  margin-top: 16px;
                `}
              `}
            >
              {description}
            </Typography.Title>
            {link && (
              <Link
                onClick={handleClickLink}
                className="link"
                isMobile={isMobile}
              >
                <Flex align="center" gap={8}>
                  <Typography.Text
                    css={css`
                      ${isMobile &&
                      css`
                        font-size: 14px;
                        line-height: 22px;
                      `}
                    `}
                  >
                    자세히 알아보기
                  </Typography.Text>
                  <ArrowRight
                    css={css`
                      ${isMobile &&
                      css`
                        width: 16px;
                        height: 16px;
                      `}
                    `}
                  />
                </Flex>
              </Link>
            )}
          </Content>
        </FlexItem>
        <FlexItem
          css={css`
            flex: 1 1 54%;
          `}
        >
          <Img
            src={imageUrl}
            alt="certicos"
            isSD={isSD}
            isTabletToLD={isTabletToLD}
          />
        </FlexItem>
      </Flex>
    </Container>
  );
};

export default BusinessDetail;

const Container = styled.section<{
  isLD: boolean;
  isMobile: boolean;
  isTabletToLD: boolean;
}>`
  display: flex;
  max-width: 1200px;
  height: 100%;
  margin: 0 auto;
  align-items: center;

  ${({ isLD }) =>
    isLD &&
    css`
      padding: 0 24px !important;
    `}

  ${({ isTabletToLD }) =>
    isTabletToLD &&
    css`
      margin-top: 120px;

      &:nth-of-type(n + 4) {
        margin-bottom: 120px;
      }
    `}

  ${({ isMobile }) =>
    isMobile &&
    `
      margin-top: 80px;

      &:nth-of-type(n + 3) {
        margin-top: 56px;
      }
      &:nth-of-type(n + 4) {
        margin-bottom: 80px;
      }
    `}
`;
const Content = styled.div<{ isIntersecting: boolean }>`
  svg,
  & > span,
  .link {
    opacity: 0;
    transition: all 0.5s ease-in-out;

    ${({ isIntersecting }) =>
      isIntersecting &&
      css`
        opacity: 1;
      `}
  }

  & > span,
  .link {
    transform: translateY(50px);

    ${({ isIntersecting }) =>
      isIntersecting &&
      css`
        transform: translateY(0);
      `}
  }

  & > span[type='b1'] {
    transition-delay: 0.3s;
  }

  .link {
    transition-delay: 0.5s;
  }
`;

const Logo = styled.div<{ isTabletToLD: boolean; isMobile: boolean }>`
  & > svg {
    ${({ isTabletToLD }) =>
      isTabletToLD &&
      css`
        width: 170px;
        height: 32px;
      `}

    ${({ isMobile }) =>
      isMobile &&
      css`
        width: 128px;
        height: 24px;
      `}
  }
`;

const Link = styled.div<{ isMobile: boolean }>`
  position: relative;
  cursor: pointer;
  width: fit-content;
  margin-top: 36px;
  padding: 14px 24px;
  box-shadow: 0 0 0 1px ${palette.BLACK} inset;
  border-radius: 31px;
  overflow: hidden;

  span,
  svg {
    position: relative;
    z-index: 1;
  }

  span {
    transition: color 0.3s ease-in-out;
  }

  svg {
    width: 24px;

    path {
      fill: ${palette.BLACK};
      transition: fill 0.3s ease-in-out;
    }
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: ${palette.BLACK};
    z-index: 0;
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.3s ease-in-out;
  }

  &:hover {
    span {
      color: ${palette.WHITE};
    }

    svg {
      path {
        fill: ${palette.WHITE};
      }
    }

    &:after {
      transform: scaleX(1);
    }
  }

  ${({ isMobile }) =>
    isMobile &&
    css`
      margin-top: 24px;
      padding: 13px 18px;
    `}
`;

const Img = styled.img<{ isSD: boolean; isTabletToLD: boolean }>`
  position: relative;
  top: 68px;
  width: 100%;
  height: 25vw;
  max-height: 320px;
  border-radius: 12px;

  ${({ isTabletToLD }) =>
    isTabletToLD &&
    css`
      top: 89px;
      height: auto;
    `}

  ${({ isSD }) =>
    isSD &&
    css`
      position: static;
      height: auto;
    `}
`;
