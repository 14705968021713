import { palette } from 'shared/styles';
import Title from './Title';
import Text from './Text';

export type TypographyProps = {
  type?:
    | 'h1'
    | 'h2'
    | 'h3'
    | 's0'
    | 's1'
    | 's2'
    | 's3'
    | 'b1'
    | 'b2'
    | 'b3'
    | 'small';
  weight?: 300 | 400 | 500 | 700;
  color?: keyof typeof palette;
  align?: React.CSSProperties['textAlign'];
};

export default { Title, Text };
