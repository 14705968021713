import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useEffect } from 'react';

import { breakPoint } from 'shared/styles';

import Footer from './Footer';

const PageLayout = ({
  scrollSnap = true,
  children,
}: {
  scrollSnap?: boolean;
  children: React.ReactNode;
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Container scrollSnap={scrollSnap}>
      {children}
      <Footer />
    </Container>
  );
};

export default PageLayout;

const Container = styled.div<{ scrollSnap: boolean }>`
  ${({ scrollSnap }) =>
    scrollSnap &&
    css`
      overflow: auto;
      max-height: 100vh;
      scroll-snap-type: y mandatory;

      & > section {
        scroll-snap-align: start;
        height: 100vh;
      }
    `}

  @media ${breakPoint.LARGE_DEVICE} {
    & > section {
      padding: 0px;
    }
  }

  section + section {
    padding-top: 80px;
  }
`;
