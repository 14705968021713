import PageLayout from 'components/layout/PageLayout';
import { useLayoutEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { isMainScrollingState } from 'store/ui';
import SectionA from './SectionA';

const CSCenter = () => {
  const setIsScroll = useSetRecoilState(isMainScrollingState);
  useLayoutEffect(() => {
    setTimeout(() => {
      setIsScroll(true);
    }, 100);
  }, [setIsScroll]);
  return (
    <PageLayout scrollSnap={false}>
      <SectionA />
    </PageLayout>
  );
};

export default CSCenter;
