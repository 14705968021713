import { useMediaQuery } from 'react-responsive';

import PageLayout from 'components/layout/PageLayout';
import { breakPoint } from 'shared/styles';
import SectionA from './SectionA';
import SectionB from './SectionB';
import SectionC from './SectionC';
import SectionD from './SectionD';
import SectionE from './SectionE';

const Business = () => {
  const isLD = useMediaQuery({ query: breakPoint.LARGE_DEVICE });

  return (
    <PageLayout scrollSnap={!isLD}>
      <SectionA />
      <SectionB />
      <SectionC />
      <SectionD />
      <SectionE />
    </PageLayout>
  );
};

export default Business;
