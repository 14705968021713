import { Modal } from 'antd';
import styled from '@emotion/styled';

import Flex from 'components/ui/Flex';
import Typography from 'components/ui/Typography';
import { ReactComponent as StibeeCloseIcon } from 'assets/icons/stibeeCloseIcon.svg';
import { ReactComponent as StibeeIcon } from 'assets/icons/stibee.svg';
import { ReactComponent as RightIcon } from 'assets/icons/right.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/check.svg';
import { ReactComponent as CheckHoverIcon } from 'assets/icons/check_hover.svg';

const StibeeModal = ({
  isStibeeModalOpen,
  setIsStibeeModalOpen,
}: {
  isStibeeModalOpen: boolean;
  setIsStibeeModalOpen: (isStibeeModalOpen: boolean) => void;
}) => {
  const handleNotShowWeek = (day: 1 | 7) => {
    setIsStibeeModalOpen(false);
    const midnight = new Date();
    midnight.setHours(24 * day, 0, 0, 0);
    window.localStorage.setItem('ST_POP', `${midnight.getTime()}`);
  };

  const handleCancel = () => {
    setIsStibeeModalOpen(false);
  };

  return (
    <Container
      open={isStibeeModalOpen}
      onCancel={handleCancel}
      width={320}
      centered
      closeIcon={<StibeeCloseIcon />}
      footer={null}
    >
      <BodyContainer>
        <Typography.Text
          style={{
            fontSize: 20,
            color: 'white',
            fontWeight: 500,
            lineHeight: 1.45,
            textAlign: 'center',
          }}
        >
          뷰티업계 소식 등{' '}
          <span style={{ fontWeight: 700 }}>다양한 정보를</span>
          <br />
          <span style={{ fontWeight: 700 }}>누구보다 빠르게</span> 받아보세요!
        </Typography.Text>
        <StibeeIcon />
        <a
          href="https://page.stibee.com/subscriptions/297387"
          target="blank"
          style={{ width: '100%' }}
        >
          <SubscribeBtn>
            <Typography.Text
              style={{ fontSize: 14, fontWeight: 500, color: 'white' }}
            >
              구독 하러 가기
            </Typography.Text>
            <RightIcon />
          </SubscribeBtn>
        </a>
      </BodyContainer>
      <Footer
        align="center"
        gap={8}
        justify="center"
        style={{ marginTop: 16, cursor: 'pointer' }}
        onClick={() => handleNotShowWeek(7)}
      >
        <CheckIcon className="check" />
        <CheckHoverIcon className="check_hover" />
        <Typography.Text style={{ fontSize: 14, color: 'white' }}>
          일주일 간 보지 않기
        </Typography.Text>
      </Footer>
    </Container>
  );
};

const Container = styled(Modal)`
  .ant-modal-content {
    border: none;
    border-radius: 16px;
    padding: 0 !important;
  }
  .ant-modal-body {
    padding: 48px 24px 24px;
    background-color: #9ebaf9;
    border-radius: 16px;
    text-align: center;
  }

  .ant-modal-close-x {
    width: 24px;
    height: 24px;
  }

  .check_hover {
    display: none !important;
  }
`;

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
`;

const Footer = styled(Flex)`
  &:hover {
    .check_hover {
      display: block !important;
    }
    .check {
      display: none !important;
    }
  }
`;

const SubscribeBtn = styled(Flex)`
  cursor: pointer;
  width: 100%;
  height: 44px;
  justify-content: center;
  align-items: center;
  background-color: #2041ba;
  border-radius: 8px;
  color: white;
  transition: all 200ms ease;
  &:hover {
    background-color: #122e96;
  }
`;

export default StibeeModal;
