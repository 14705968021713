import { css } from '@emotion/react';
import styled from '@emotion/styled';

import Flex from 'components/ui/Flex';
import Typography from 'components/ui/Typography';
import { useMediaQuery } from 'react-responsive';
import { breakPoint } from 'shared/styles';

const SectionC = () => {
  const isMobile = useMediaQuery({ query: breakPoint.MOBILE });
  const isTTLD = useMediaQuery({ query: breakPoint.TABLET_TO_LARGE_DEVICE });

  return (
    <Container $isMobile={isMobile}>
      <Flex justify="center" align="center" style={{ height: '100%' }}>
        <Typography.Title
          color="WHITE"
          css={css`
            width: 1200px;
            position: relative;

            ${isMobile &&
            css`
              padding: 0 24px;
              font-size: 24px;
              line-height: 34px;
              word-break: keep-all;
              width: 100%;
            `}

            ${isTTLD &&
            css`
              padding: 0 24px;
              font-size: 36px;
              line-height: 46px;
              width: 100%;
            `}
          `}
        >
          우리는 화장품 산업의 판도를 바꿀 것입니다.
          <br />더 효율적이게.
        </Typography.Title>
      </Flex>
    </Container>
  );
};

export default SectionC;

const Container = styled.section<{ $isMobile: boolean }>`
  background-image: url('https://cdri.s3.ap-northeast-2.amazonaws.com/assets/aboutus-c.png');
  background-size: cover;
  background-position: center;
  clear: both;

  ${({ $isMobile }) =>
    $isMobile &&
    css`
      height: 100vh;
    `}
`;
